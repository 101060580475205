import c from 'clsx'
import { ComponentProps, FC } from 'react'

const GridTable: FC<ComponentProps<'div'>> = ({ children, className, ...other }) => {
  return (
    <div className={c('grid divide-y-1 divide-separators-primary', className)} {...other}>
      {children}
    </div>
  )
}

const GridTableRow: FC<ComponentProps<'div'>> = ({ children, className, ...other }) => {
  return (
    <div
      className={c('col-span-full grid grid-cols-subgrid divide-x-1 divide-separators-primary', className)}
      {...other}
    >
      {children}
    </div>
  )
}

const GridTableCell: FC<ComponentProps<'div'>> = ({ children, className, ...other }) => {
  return (
    <div className={c('px-7 py-4', className)} {...other}>
      {children}
    </div>
  )
}

const GridButton: FC<ComponentProps<'button'>> = ({ children, className, ...other }) => {
  return (
    <button
      className={c('flex items-center gap-x-4 px-7 py-4 text-labels-tertiary hover:text-labels-secondary', className)}
      {...other}
    >
      {children}
    </button>
  )
}

export { GridTable, GridTableRow, GridTableCell, GridButton }
