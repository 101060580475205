import { FC } from 'react'

interface EntityPageProps {
  children: React.ReactNode
}

const EntityPage: FC<EntityPageProps> = ({ children }) => {
  return (
    <div className='px-6'>
      <div className='container mx-auto py-12'>{children}</div>
    </div>
  )
}

export { EntityPage }
