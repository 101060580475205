import { useEffect, useState } from 'react'
import * as React from 'react'
import c from 'clsx'
import { Transition } from '@headlessui/react'
import {
  GuarantorSignMethod,
  GuarantorStatus,
  useAddGuarantorMutation,
  useAddWithCompanyGuarantorMutation,
  useDealWithGuarantorsAndCompletedContactsQuery
} from '../../../graphql/schema'
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber'
import useNodes from '../../../hooks/useNodes'
import RadioButtonsGroup from '../../../components/RadioButtonsGroup'
import Suggestions from '../../../components/Suggestions'
import { CompanyData } from '../../../types/dadata'
import { SubmitHandler, useForm } from 'react-hook-form'
import Input from '../../../components/Input'
import useThrottledState from '../../../hooks/useThrottledState'
import { useCompanySuggestions } from '../../../hooks/useSuggestions'
import SubmitButton from '../../../components/SubmitButton'
import getCompanyName from '../../../utils/getCompanyName'

import { ReactComponent as LoadingIcon } from '../../../svg/icons/loading.svg'
import { ReactComponent as PlusIcon } from '../../../svg/icons/plus.svg'

interface GuarantorFormProps {
  onDone?: (guarantorId: number) => void
  dealId: number
}

type modeType = 'person' | 'company'

const GuarantorForm: React.FC<GuarantorFormProps> = ({ dealId, onDone }) => {
  const { data: dealData } = useDealWithGuarantorsAndCompletedContactsQuery({
    variables: { id: dealId?.toString() },
    skip: !dealId,
    fetchPolicy: 'cache-and-network'
  })

  const deal = dealData?.deal

  const existingGuarantorContactIds = deal?.guarantors?.edges?.map((e) => e?.node?.contact?._id) || []
  const contacts =
    useNodes(deal?.application?.contacts?.edges).filter(
      (c) =>
        !existingGuarantorContactIds.includes(c?._id) &&
        (c.verificationAcceptExpiresAt ? new Date() < new Date(c.verificationAcceptExpiresAt) : true)
    ) || []

  const [selectedContact, setselectedContact] = useState<(typeof contacts)[0]>()

  const [addGuarantor, { loading }] = useAddGuarantorMutation()
  const [addGuarantorByCompany, { loading: loadingAddGuarantorByCompany }] = useAddWithCompanyGuarantorMutation()

  const [mode, setMode] = useState<modeType>('person')

  const { register, handleSubmit, watch, setValue, formState } = useForm<{ company: string }>()

  const [company, setCompany] = useState<CompanyData>()

  const companyQuery = watch('company')

  const [throttledCompanyQuery] = useThrottledState(companyQuery, 500)
  const suggestions = useCompanySuggestions(throttledCompanyQuery)

  // при выборе компании устанавливаем её имя в поле компании
  useEffect(() => {
    if (!company) return
    setValue('company', getCompanyName(company), {
      shouldValidate: true
    })
  }, [company, setValue])

  if (!deal) return null

  const submitForm = async () => {
    const contact = selectedContact

    if (!contact) return

    const result = await addGuarantor({
      variables: {
        input: {
          dealId: deal._id.toString(),
          contactId: contact._id.toString(),
          status: GuarantorStatus.NotSigned,
          signMethod: GuarantorSignMethod.Personal
        }
      }
    })

    if (result.data?.addGuarantor?.guarantor?._id) {
      if (onDone) onDone(result.data?.addGuarantor?.guarantor?._id)
    }
  }

  const onCompanySubmit: SubmitHandler<{ company: string }> = async () => {
    if (!company) return

    const result = await addGuarantorByCompany({
      variables: {
        input: {
          dealId: deal._id.toString(),
          companyInn: company.inn,
          status: GuarantorStatus.NotSigned,
          signMethod: GuarantorSignMethod.Personal
        }
      }
    })

    if (result.data?.addWithCompanyGuarantor?.guarantor?._id) {
      if (onDone) onDone(result.data?.addWithCompanyGuarantor?.guarantor?._id)
    }
  }

  return (
    <section className='w-[448px] py-12'>
      <h1 className='mb-12 px-12 font-display text-h200'>Выбор поручителей</h1>

      <RadioButtonsGroup
        className='my-12 px-12'
        onChange={(value) => setMode(value as modeType)}
        options={[
          ['Физическое лицо', 'person'],
          ['Юридическое лицо', 'company']
        ]}
        checkedValue={mode}
      />

      {mode === 'person' && (
        <div>
          {contacts.length ? (
            <div className='px-12 text-grayscale-150'>Можно выбрать только верифицированные контакты</div>
          ) : (
            <div className='px-12 text-center text-grayscale-150'>В этой сделке пока нет верифицированных физлиц</div>
          )}

          {/* <h3 className='mt-12 px-12'>Электронное подписание?</h3>
          <div className='px-12 mt-6'>
            <RadioButtonsGroup options={[["Да", "1"], ["Нет", "0"]]} />
          </div> */}

          <form>
            <div className='flex flex-col'>
              {contacts.map((contact) => (
                <div
                  key={contact._id}
                  className={c('cursor-pointer px-12 py-6', selectedContact?._id === contact._id && 'bg-grayscale-450')}
                  onClick={() => setselectedContact(contact)}
                >
                  <div className='mb-3 flex items-center justify-between font-display text-h200 tabular-nums'>
                    <h3>{contact.fio}</h3>
                  </div>
                  <div className='flex items-center gap-5 text-p200 tabular-nums text-grayscale-150'>
                    <div>{contact.phone && formatPhoneNumber(contact.phone)}</div>
                    <div className='h-2 w-2 rounded-full bg-grayscale-250' />
                    <div>{contact.email}</div>
                  </div>
                </div>
              ))}
            </div>
            {!!contacts.length && (
              <div className='mt-12 px-12'>
                <button
                  type='button'
                  onClick={submitForm}
                  className='relative h-27 w-full overflow-hidden rounded-xl bg-red-100 px-20 text-p200 font-semibold leading-full text-white-0 ring-red-100 hover:bg-red-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-red-100 active:bg-red-150 disabled:bg-grayscale-450 disabled:text-grayscale-250'
                >
                  <Transition
                    as='div'
                    show={!loading}
                    className='relative flex transform items-center justify-center transition-all duration-300'
                    enterFrom='-translate-x-1/2 opacity-0'
                    enterTo='translate-x-0 opacity-100'
                    leaveTo='translate-x-1/2 opacity-0'
                  >
                    <PlusIcon className='mr-5' />
                    Добавить
                  </Transition>
                  <Transition
                    as='div'
                    show={loading}
                    className='absolute inset-0 flex transform items-center justify-center transition-all duration-300'
                    enterFrom='-translate-x-1/4 opacity-0'
                    enterTo='translate-x-0 opacity-100'
                    leaveTo='translate-x-1/4 opacity-0'
                  >
                    <LoadingIcon className='animate-spin' />
                  </Transition>
                </button>
              </div>
            )}
          </form>
        </div>
      )}
      {mode === 'company' && (
        <div>
          <form onSubmit={handleSubmit(onCompanySubmit)}>
            <div className='px-12'>
              <Suggestions<CompanyData>
                suggestions={suggestions?.map((s) => ({
                  key: s._id,
                  title: getCompanyName(s),
                  subtitle: `${s.inn} ${s.address?.value || ''}`,
                  payload: s,
                  lineThrough: !!s.state.liquidation_date
                }))}
                select={(suggestion) => setCompany(suggestion.payload)}
              >
                <Input
                  label='Компания, ИП или ИНН'
                  type='text'
                  placeholder='Начните вводить название или ИНН'
                  autoComplete='off'
                  {...register('company', {
                    required: true,
                    validate: {
                      // требуем выбора из предложенных вариантов только если апи подсказок работает
                      fromSuggested: (value) => {
                        return value !== getCompanyName(company) ? 'Требуется выбрать один из вариантов' : true
                      }
                    }
                  })}
                  error={formState.errors.company}
                />
              </Suggestions>
            </div>
            <div className='mt-12 px-12'>
              <SubmitButton loading={loading || loadingAddGuarantorByCompany}>
                <PlusIcon className='mr-5' />
                Добавить
              </SubmitButton>
            </div>
          </form>
        </div>
      )}
    </section>
  )
}

export default GuarantorForm
