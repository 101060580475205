import { FC, useEffect, useCallback } from 'react'
import { ReactComponent as PlusIcon } from '../../../svg/ui/plus.svg'
import { ReactComponent as TrashIcon } from '../../../svg/ui/delete.svg'
import { useUnderwritingQuery, useUpdateUnderwritingMutation } from '../../../graphql/schema.tsx'
import { Card, CardHeader, CardTitle } from '../../../components/Card.tsx'
import { useParams } from 'react-router-dom'
import ScoringInput from './ScoringInput.tsx'
import { useFieldArray, useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'
import StylelessTextAreaInput from '../../../components/StylelessTextAreaInput.tsx'

type RiskItem = {
  description: string
  mitigationMeasure: string
}

interface RisksFormValues {
  decision: string
  risks: RiskItem[]
}

const Underwriting: FC = () => {
  const { id } = useParams<'id'>()

  const [updateUnderwriting] = useUpdateUnderwritingMutation()

  const { data: underwritingData } = useUnderwritingQuery({
    variables: {
      applicationId: parseInt(`${id}`)
    },
    skip: !id
  })
  const underwriting = underwritingData?.underwriting

  const { register, control, setValue, getValues } = useForm<RisksFormValues>()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'risks'
  })

  useEffect(() => {
    if (!getValues('decision') && underwriting?.decision) {
      setValue('decision', underwriting.decision)
    }
    if (!getValues('risks')?.length && underwriting?.risks) {
      setValue('risks', underwriting.risks)
    }
  }, [underwriting, setValue, getValues])

  const updateRisks = async () => {
    const risks = getValues('risks').filter((f) => !!f.description || !!f.mitigationMeasure)

    await updateUnderwriting({
      variables: {
        applicationId: parseInt(`${id}`),
        input: {
          risks
        }
      }
    })
  }

  const updateDecision = async () => {
    const decision = getValues('decision')
    if (decision === underwriting?.decision) return

    await updateUnderwriting({
      variables: {
        applicationId: parseInt(`${id}`),
        input: {
          decision
        }
      }
    })
  }

  const debouncedUpdateRisks = useCallback(debounce(500, updateRisks), [updateRisks])
  const debouncedUpdateDecision = useCallback(debounce(500, updateDecision), [updateRisks])

  const handleRiskAdd = () => append({ description: '', mitigationMeasure: '' })

  return (
    <Card>
      <CardHeader>
        <CardTitle>Оценка кредитного риска</CardTitle>
      </CardHeader>

      <div className='flex flex-col gap-6 px-5 pb-5'>
        <div className='w-full rounded-lg bg-surface-primary shadow-card'>
          {!!fields.length && (
            <table className='w-full'>
              <thead className='text-labels-tertiary'>
                <tr>
                  <th className='border-b-1 border-r border-grayscale-400 px-7 py-6 text-left'>Риски</th>
                  <th className='border-b-1 border-grayscale-400 px-7 py-6 text-left'>Меры по снижению</th>
                  <th className='w-[45px] border-b-1 border-grayscale-400' />
                </tr>
              </thead>
              <tbody>
                {fields.map((field, index) => (
                  <tr key={field.id}>
                    <td
                      className='cursor-text border-b-1 border-r border-grayscale-400 align-top focus-within:ring-1 focus-within:ring-inset focus-within:ring-base-red/75'
                      onClick={(e) => {
                        const td = e.currentTarget
                        const input = td.querySelector('textarea')
                        if (e.target === td && input) {
                          input.focus()
                        }
                      }}
                    >
                      <StylelessTextAreaInput
                        autoComplete='off'
                        {...register(`risks.${index}.description`, {
                          onChange: debouncedUpdateRisks
                        })}
                      />
                    </td>
                    <td
                      className='cursor-text border-b-1 border-r border-grayscale-400 align-top focus-within:ring-1 focus-within:ring-inset focus-within:ring-base-red/75'
                      onClick={(e) => {
                        const td = e.currentTarget
                        const input = td.querySelector('textarea')
                        if (e.target === td && input) {
                          input.focus()
                        }
                      }}
                    >
                      <StylelessTextAreaInput
                        autoComplete='off'
                        {...register(`risks.${index}.mitigationMeasure`, {
                          onChange: debouncedUpdateRisks
                        })}
                      />
                    </td>
                    <td className='border-b-1 border-grayscale-400'>
                      <button
                        type='button'
                        tabIndex={-1}
                        onClick={async () => {
                          remove(index)
                          await updateRisks()
                        }}
                        className='flex items-center px-7 py-6 text-labels-tertiary hover:text-base-red'
                      >
                        <TrashIcon className='mx-auto cursor-pointer' />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          <button
            type='button'
            className='flex w-full items-center gap-4 px-7 py-6 text-body-s text-labels-tertiary hover:text-base-red'
            onClick={handleRiskAdd}
          >
            <PlusIcon />
            Новый риск
          </button>
        </div>

        <div className='flex gap-12 rounded-md bg-surface-primary p-10 shadow-card'>
          <h2 className='font-display text-h200 font-medium'>Решение:</h2>

          <div className='w-full'>
            <ScoringInput
              autoComplete='off'
              {...register('decision', {
                onChange: debouncedUpdateDecision
              })}
            />
          </div>
        </div>
      </div>
    </Card>
  )
}

export default Underwriting
