import { FC, ComponentProps } from 'react'
import c from 'clsx'

const Card: FC<ComponentProps<'div'>> = ({ children, className, ...other }) => {
  return (
    <section className={c('rounded-xl bg-surface-secondary', className)} {...other}>
      {children}
    </section>
  )
}

const CardHeader: FC<ComponentProps<'div'>> = ({ children, className, ...other }) => {
  return (
    <div className={c('flex items-center justify-between px-5 py-4', className)} {...other}>
      {children}
    </div>
  )
}

const CardTitle: FC<ComponentProps<'div'>> = ({ children, className, ...other }) => {
  return (
    <h3
      className={c(
        'min-w-0 overflow-hidden text-ellipsis whitespace-nowrap px-3 py-2 text-title-m font-semibold',
        className
      )}
      {...other}
    >
      {children}
    </h3>
  )
}

const CardMenu: FC<ComponentProps<'div'>> = ({ children, className, ...other }) => {
  return (
    <div className={c('flex items-center gap-2', className)} {...other}>
      {children}
    </div>
  )
}

const CardAccentButton: FC<ComponentProps<'button'>> = ({ children, className, ...other }) => {
  return (
    <button
      className={c(
        'flex items-center gap-x-3 rounded-md bg-surface-primary px-5 py-2 font-medium text-labels-secondary shadow-card hover:text-labels-primary hover:shadow-card-hover active:bg-surface-secondary disabled:cursor-not-allowed disabled:text-labels-tertiary',
        className
      )}
      {...other}
    >
      {children}
    </button>
  )
}

const CardButton: FC<ComponentProps<'button'>> = ({ children, className, ...other }) => {
  return (
    <button
      className={c(
        'flex items-center gap-x-3 rounded-md px-5 py-2 font-medium text-labels-tertiary hover:bg-surface-tertiary hover:text-labels-primary',
        className
      )}
      {...other}
    >
      {children}
    </button>
  )
}

const CardSeparator: FC<ComponentProps<'div'>> = ({ className, ...other }) => {
  return <div className={c('mx-6 h-10 w-1px flex-none bg-separators-secondary', className)} {...other} />
}

const CardIconButton: FC<ComponentProps<'button'>> = ({ children, className, ...other }) => {
  return (
    <button
      className={c(
        'rounded-md p-3 font-medium text-labels-tertiary hover:bg-surface-tertiary hover:text-labels-primary',
        className
      )}
      {...other}
    >
      {children}
    </button>
  )
}

export { Card, CardHeader, CardTitle, CardMenu, CardButton, CardIconButton, CardAccentButton, CardSeparator }
