import { FC, useEffect, useMemo, useState } from 'react'
import { ReactComponent as FileIcon } from '../../../../svg/icons/fileDownload.svg'
import Input from '../../../../components/Input.tsx'
import { SubmitHandler, useForm } from 'react-hook-form'
import downloadFile from '../../../../utils/downloadFileServerless.ts'
import { CompanyData } from '../../../../types/dadata.ts'
import {
  capitalizeFirst,
  capitalizeFirstInWords,
  getCompanyForm,
  getDateFormattedInDocumentName,
  getDateFormattedString,
  getFormattedContractNumber,
  isIP
} from '../../../../utils/contractUtils.ts'
import {
  DealSupplyMode,
  DocumentsQuery,
  DocumentTypeEntity,
  useDealForCollectionSuspenseQuery,
  useDealSuppliesSuspenseQuery,
  useCreateCollectionClaimPdfMutation
} from '../../../../graphql/schema.tsx'
import SubmitButton from '../../../../components/SubmitButton'
import useNodes from '../../../../hooks/useNodes.ts'
import { Company } from '../../models'
import MaskedInput from '../../../../components/MaskedInput.tsx'
import useMultipleEntitySupplyDocs from '../hooks/useMultipleEntitySupplyDocs.tsx'
import {
  SHIPPING_FOLDER_NAME,
  SPECIFICATIONS_FOLDER_NAME,
  SUPPLY_FOLDER_NAME,
  TRANSFER_FOLDER_NAME
} from '../../../../config.ts'
import { NodeType } from '../../../../types'
import { Claim } from './CollectionForm.tsx'
import useClaimSchedule from '../hooks/useClaimSchedule.tsx'
import getInclineFIO from '../../../../utils/getInclineFIO.ts'
import getFileNameWithoutExtension from '../../../../utils/getFileNameWithoutExtension.ts'
import usePersonAddress from '../../../../hooks/usePersonAddress.ts'

interface ClaimFormProps {
  dealId: number
}

type Inputs = {
  company: Company
  claimN: string
  claimDate: string
  pretensionN: string
  pretensionDate: string
  contractN: string
  contractDate: string
  claimSchedule: Claim[]
  supplies: {
    companyName: string
    supplyDocs: string
    shippingDocs: string
    transferDocs: string
  }[]
  inclineFio?: string
}

const filteredDocsName = (docs: NodeType<DocumentsQuery['documents']>[]) => {
  if (!docs?.length) return ''

  return docs
    .filter((doc) => doc.mimeType === 'application/pdf' && doc.originalName)
    .map((doc) => getFileNameWithoutExtension(doc.originalName))
    .join(', ')
}

const ClaimForm: FC<ClaimFormProps> = ({ dealId }) => {
  const { data } = useDealForCollectionSuspenseQuery({ variables: { id: `${dealId}` } })
  const deal = data?.deal

  const { data: dealSuppliesData } = useDealSuppliesSuspenseQuery({ variables: { id: `${dealId}` } })
  const supplies = useNodes(dealSuppliesData?.deal?.dealSupplies?.edges)

  const supplyDocs = useMultipleEntitySupplyDocs({
    entityIds: supplies.map((s) => s._id),
    entityType: DocumentTypeEntity.DealSupply,
    folderName: SUPPLY_FOLDER_NAME
  })
  const specificationsDocs = useMultipleEntitySupplyDocs({
    entityIds: supplies.map((s) => s._id),
    entityType: DocumentTypeEntity.DealSupply,
    folderName: SPECIFICATIONS_FOLDER_NAME
  })
  const shippingDocs = useMultipleEntitySupplyDocs({
    entityIds: supplies.map((s) => s._id),
    entityType: DocumentTypeEntity.DealSupply,
    folderName: SHIPPING_FOLDER_NAME
  })
  const transferDocs = useMultipleEntitySupplyDocs({
    entityIds: supplies.map((s) => s._id),
    entityType: DocumentTypeEntity.DealSupply,
    folderName: TRANSFER_FOLDER_NAME
  })

  const IPfactAddress = usePersonAddress(deal?.customerCompany.inn || '')

  const { getClaimSchedule } = useClaimSchedule(dealId)

  const [createClaim] = useCreateCollectionClaimPdfMutation()

  const companyDadata: CompanyData = useMemo(
    () => (deal?.customerCompany?.dadata ? deal?.customerCompany.dadata.data : {}),
    [deal?.customerCompany]
  )

  const IP: boolean = isIP(companyDadata)
  const companyName = IP ? capitalizeFirstInWords(companyDadata.name?.full) : capitalizeFirst(companyDadata.name?.full)

  const form = useForm<Inputs>({
    defaultValues: {
      company: {
        formShort: deal?.customerCompany?.shortWithOpf
          ? getCompanyForm(deal?.customerCompany?.shortWithOpf, companyName)
          : '',
        form: deal?.customerCompany?.fullWithOpf
          ? getCompanyForm(deal?.customerCompany?.fullWithOpf, companyName, true)
          : '',
        name: companyName,
        requisites: {
          kpp: !IP ? companyDadata.kpp : undefined,
          inn: deal?.customerCompany.inn,
          legalAddress: IP ? IPfactAddress : companyDadata.address?.value,
          ogrn: companyDadata.ogrn || ''
        }
      },
      claimDate: new Date().toISOString().split('T').shift(),
      pretensionDate: new Date().toISOString().split('T').shift(),
      contractN: getFormattedContractNumber(deal?.contractNumber),
      contractDate: getDateFormattedString(new Date(deal?.contractDate as string)),
      inclineFio: IP ? getInclineFIO(companyName) : undefined,
      supplies: supplies?.map((supply) => {
        const supplyCompanyDadata: CompanyData = supply?.supplierCompany?.dadata
          ? supply?.supplierCompany.dadata.data
          : {}
        const supplyCompanyIP: boolean = isIP(supplyCompanyDadata)
        const supplyCompanyName = supplyCompanyIP
          ? capitalizeFirstInWords(supplyCompanyDadata.name?.full)
          : capitalizeFirst(supplyCompanyDadata.name?.full)

        return {
          companyName: supply?.supplierCompany?.shortWithOpf
            ? `${getCompanyForm(supply?.supplierCompany?.shortWithOpf, supplyCompanyName)} ${
                supplyCompanyIP ? supplyCompanyName : '«' + supplyCompanyName + '»'
              }`
            : '',
          supplyDocs:
            supply.mode === DealSupplyMode.Frame
              ? `${getFileNameWithoutExtension(supply.frameContractDocument?.originalName)}, ${filteredDocsName(
                  specificationsDocs[supply._id]
                )}`
              : filteredDocsName(supplyDocs[supply._id]),
          shippingDocs: supply.shippingDone ? filteredDocsName(shippingDocs[supply._id]) : '',
          transferDocs: supply.shippingDone ? filteredDocsName(transferDocs[supply._id]) : ''
        }
      })
    }
  })
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = form

  useEffect(() => {
    if (IPfactAddress && IP) {
      setValue('company.requisites.legalAddress', IPfactAddress)
    }
  }, [IPfactAddress, setValue, IP])

  const [loading, setLoading] = useState(false)
  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    if (loading) {
      return
    }
    setLoading(true)

    const company = {
      ...data.company,
      requisites: {
        ...data.company.requisites,
        // FIXME: надо что-то сделать с дефолтным адресом компании либо тут либо в генераторе
        legalAddress: data.company.requisites.legalAddress || ''
      }
    }

    try {
      const claimSchedule = (await getClaimSchedule(data.pretensionDate))?.map((c) => ({
        amount: c.amount,
        date: c.date ? getDateFormattedString(new Date(c.date)) : '',
        number: c.number,
        overdueDays: c.overdueDays || undefined,
        overdueEnd: c.overdueEnd ? getDateFormattedString(new Date(c.overdueEnd)) : undefined,
        overdueFrom: c.overdueFrom ? getDateFormattedString(new Date(c.overdueFrom)) : undefined,
        paidAmount: c.paidAmount || undefined,
        paidPenaltyAmount: c.paidPenaltyAmount || undefined,
        penaltyAmount: c.penaltyAmount || undefined,
        transactionPaidAt: c.transactionPaidAt ? getDateFormattedString(new Date(c.transactionPaidAt)) : undefined
      }))

      if (!claimSchedule) throw new Error('')

      const createClaimResult = await createClaim({
        variables: {
          input: {
            company,
            claimSchedule,
            claimN: data.claimN,
            claimDate: getDateFormattedString(new Date(data.claimDate)),
            contractN: data.contractN,
            contractDate: data.contractDate,
            pretensionN: data.pretensionN,
            pretensionDate: getDateFormattedString(new Date(data.pretensionDate)),
            supplies: data.supplies,
            inclineFio: data?.inclineFio || undefined
          }
        }
      })

      const claimUrl = createClaimResult?.data?.createCollectionClaim?.url
      if (!claimUrl) throw new Error('Не удалось получить ссылку на файл исковое')

      await downloadFile(
        claimUrl,
        `(лп)${getDateFormattedInDocumentName(new Date())} Исковое №${data.claimN} от ${getDateFormattedString(
          new Date(data.claimDate)
        )}.pdf`
      )
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  if (!deal) return null

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='mb-8 grid grid-cols-2 gap-20'>
        <MaskedInput
          label='Номер искового'
          type='text'
          placeholder='АС-00-00-00/0'
          mask='АС-00-00-00/00'
          {...register('claimN', {
            required: true,
            validate: (value) => {
              if (value?.length < 13) {
                return 'Введите номер искового полностью (АС-00-00-00/0)'
              }
              return true
            }
          })}
          error={errors.claimN}
        />
        <Input label='Дата документа' type='date' {...register('claimDate', { required: true })} />
      </div>
      <div className='mb-8 grid grid-cols-2 gap-20'>
        <MaskedInput
          label='Номер претензии'
          type='text'
          placeholder='ПТЗ000000-00-00'
          mask='ПТЗ000000-00-00'
          {...register('pretensionN', {
            required: true,
            validate: (value) => {
              if (value?.length !== 15) {
                return 'Введите номер претензии полностью (ПТЗ000000-00-00)'
              }
              return true
            }
          })}
          error={errors.pretensionN}
        />
        <Input label='Дата претензии' type='date' {...register('pretensionDate', { required: true })} />
      </div>
      <div className='grid grid-cols-2 gap-20'>
        <div>
          <Input
            label='Юридический адрес клиента'
            type='text'
            autoComplete='off'
            placeholder='105005, г. Москва, Бакунинская улица, дом 4-6, строение 1'
            {...register('company.requisites.legalAddress', { required: true })}
          />
        </div>
        {IP && (
          <Input
            label='Взыскать с'
            type='text'
            autoComplete='off'
            placeholder='Иванова Ивана Ивановича'
            {...register('inclineFio', { required: true })}
          />
        )}
      </div>
      {supplies?.map((supply, index) => (
        <div
          key={supply._id}
          className='mb-8 mt-15 grid grid-cols-2 gap-x-20 gap-y-8 border-t-1 border-grayscale-450 pt-12'
        >
          <Input
            readOnly
            type='text'
            label={`Поставщик ${index + 1}`}
            placeholder='Петров Петр Петрович или ООО «ФЕРА»'
            {...register(`supplies.${index}.companyName`, { required: true })}
            error={errors.supplies?.[index]?.companyName}
          />
          <Input
            label='Договор поставки'
            type='text'
            {...register(`supplies.${index}.supplyDocs`, { required: true })}
            error={errors.supplies?.[index]?.supplyDocs}
          />
          {supply.shippingDone && (
            <>
              <Input
                type='text'
                label='Документы отгрузки'
                {...register(`supplies.${index}.shippingDocs`, { required: true })}
                error={errors.supplies?.[index]?.shippingDocs}
              />
              <Input
                type='text'
                label='Дата акта приема-передачи'
                {...register(`supplies.${index}.transferDocs`, { required: true })}
                error={errors.supplies?.[index]?.transferDocs}
              />
            </>
          )}
        </div>
      ))}
      <SubmitButton className='mt-12' loading={loading}>
        <FileIcon className='mr-5' />
        Сгенерировать
      </SubmitButton>
    </form>
  )
}

export default ClaimForm
