import { FC } from 'react'
import ohNoImage from '../images/ohno.jpg'
import useCurrentUser from '../hooks/useCurrentUser'
import { ReactComponent as WarningIcon } from '../svg/icons/warning.svg'

const ErrorFallback: FC<{ error?: Error }> = ({ error }) => {
  const currentUser = useCurrentUser()
  console.log(error)

  const name = currentUser?.name || 'Коллега'

  return (
    <div className='flex min-h-screen w-full flex-col items-center justify-center'>
      <h1 className='mb-10 font-display text-h100 font-medium'>Всё сломалось!</h1>
      <div className='relative'>
        <WarningIcon width={60} height={60} className='absolute left-full translate-x-20 rotate-12 text-base-red' />
        <WarningIcon width={80} height={80} className='absolute right-full -translate-x-20 -rotate-12 text-base-red' />
        <WarningIcon
          width={60}
          height={60}
          className='absolute left-full translate-x-60 translate-y-120 -rotate-12 text-base-red'
        />
        <WarningIcon
          width={70}
          height={70}
          className='absolute right-full -translate-x-70 translate-y-120 rotate-12 text-base-red'
        />
        <WarningIcon
          width={70}
          height={70}
          className='absolute left-full top-full -translate-y-30 translate-x-20 rotate-12 text-base-red'
        />
        <WarningIcon
          width={50}
          height={50}
          className='absolute right-full top-full -translate-x-20 -translate-y-30 -rotate-12 text-base-red'
        />

        <div className='bg-base-black absolute left-10 top-40 px-5 text-base-white'>
          {name}, вставай,
          <br /> мы всё уронили
        </div>

        <div className='bg-base-black absolute right-10 top-90 px-5 text-base-white'>
          Мы уронили вообще
          <br /> всё, {name}, честно
        </div>

        <div className='bg-base-black absolute left-10 top-130 px-5 text-base-white'>
          Попробуй страницу
          <br /> обновить или позвать
          <br /> на помощь
        </div>

        {!!error?.name && !!error?.message && (
          <div className='bg-base-black absolute bottom-10 left-50 right-10 px-5 text-base-white'>
            {error.name} {error.message}, {name}, понимаешь?
          </div>
        )}

        <img className='w-[420px] rounded-xl' src={ohNoImage} alt='всё пропало' />
      </div>
    </div>
  )
}

export default ErrorFallback
