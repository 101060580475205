import { FC, ComponentProps, ReactNode } from 'react'
import { ReactComponent as LoadingIcon } from '../../svg/icons/loading.svg'
import { Transition } from '@headlessui/react'
import c from 'clsx'

interface SubmitButtonProps {
  children: ReactNode
  className?: string
  loading?: boolean
}

const SubmitButton: FC<SubmitButtonProps & ComponentProps<'button'>> = ({
  children,
  loading = false,
  className,
  ...otherProps
}) => {
  return (
    <button
      type='submit'
      className={c(
        'relative h-27 w-full overflow-hidden rounded-xl bg-red-100 px-20 text-p200 font-semibold leading-full text-white-0 ring-red-100 hover:bg-red-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-red-100 active:bg-red-150',
        className,
        // у кнопки есть три стейта - обычный, disabled при невыполнении условий нажатия, тогда она будет серая с серым текстом, и disabled во время loading -
        // тогда из-за тернарного оператора стили loading будут приоритетнее, и при сабмите кнопка будет и disabled, и красного цвета
        loading
          ? 'bg-red-100 text-white-0'
          : 'disabled:cursor-not-allowed disabled:bg-grayscale-450 disabled:text-grayscale-250'
      )}
      {...otherProps}
      disabled={otherProps.disabled || loading}
    >
      <Transition
        as='div'
        show={!loading}
        className='relative flex transform items-center justify-center transition-all duration-300'
        enterFrom='-translate-x-1/2 opacity-0'
        enterTo='translate-x-0 opacity-100'
        leaveTo='translate-x-1/2 opacity-0'
      >
        {children}
      </Transition>
      <Transition
        as='div'
        show={loading}
        className='absolute inset-0 flex transform items-center justify-center transition-all duration-300'
        enterFrom='-translate-x-1/4 opacity-0'
        enterTo='translate-x-0 opacity-100'
        leaveTo='translate-x-1/4 opacity-0'
      >
        <LoadingIcon className='animate-spin' />
      </Transition>
    </button>
  )
}

export default SubmitButton
