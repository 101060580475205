import { FC } from 'react'
import { PaymentKind, PaymentTargetType, useCreatePaymentMutation } from '../../../graphql/schema.tsx'
import { ReactComponent as PlusIcon } from '../../../svg/icons/plus.svg'
import Input from '../../../components/Input.tsx'
import { SubmitHandler, useForm } from 'react-hook-form'
import { handleBackendErrorsToForm } from '../../../utils/backendErrorUtils.ts'
import { ApolloError } from '@apollo/client'
import MaskedInput from '../../../components/MaskedInput.tsx'
import parseDecimal from '../../../utils/parseDecimal.ts'
import { paymentKindDict } from '../../../utils/dictionaries.ts'
import Select from '../../../components/Select.tsx'
import SubmitButton from '../../../components/SubmitButton'
import c from 'clsx'

type Inputs = {
  date: string
  amount: string
  kind: PaymentKind
  targetId: string
}

interface PaymentFormProps {
  dealId: number
  onDone?: () => void
  kinds?: PaymentKind[]
}

const PaymentForm: FC<PaymentFormProps> = ({ dealId, onDone, kinds }) => {
  const [createPayment, { loading }] = useCreatePaymentMutation()

  const { register, handleSubmit, setError, watch, setValue, getValues } = useForm<Inputs>()

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (loading) return

    await createPayment({
      variables: {
        input: {
          date: data.date,
          amount: parseDecimal(data.amount.replace(/\s/g, '')),
          kind: data.kind || PaymentKind.Leasing,
          targetType: PaymentTargetType.Deal,
          targetId: dealId.toString()
        }
      }
    })
      .then(() => {
        if (onDone) onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<Inputs>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  return (
    <section className='md: w-[448px] p-12'>
      <h1 className='mb-12 font-display text-h200'>Новый платеж</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='relative mb-12 flex flex-col gap-8'>
          {!!kinds && (
            <Select
              label='Назначение платежа'
              placeholder='Выберите назначение платежа'
              type='text'
              autoComplete='off'
              {...register('kind', { required: true })}
              value={paymentKindDict[watch('kind')] || ''}
            >
              {kinds.map((kind) => (
                <li
                  key={kind}
                  onClick={() => {
                    setValue('kind', kind)
                    setValue('amount', kind === PaymentKind.DebtTransferService ? '20 000' : '')
                  }}
                  className={c(
                    'cursor-pointer px-12 py-5 hover:bg-grayscale-450',
                    getValues('kind') === kind && 'bg-grayscale-400'
                  )}
                >
                  <div className='mb-1 text-grayscale-0'>{paymentKindDict[kind]}</div>
                </li>
              ))}
            </Select>
          )}
          <Input label='Дата платежа' type='date' {...register('date', { required: true })} />
          <MaskedInput
            label='Сумма'
            placeholder='15000'
            inputMode='decimal'
            mask='number'
            onAccept={(value) => setValue('amount', value)}
            {...register('amount', { required: true, min: 0 })}
          />
        </div>
        <SubmitButton loading={loading}>
          <PlusIcon className='mr-5' />
          Создать
        </SubmitButton>
      </form>
    </section>
  )
}

export default PaymentForm
