import { forwardRef, ComponentPropsWithoutRef } from 'react'
import { FieldError } from 'react-hook-form'
import c from 'clsx'
import { errorToString } from '../../../utils/errorToString'

interface InputProps {
  label?: string
  labelClassName?: string
  error?: string | FieldError
}

const StylelessInput = forwardRef<HTMLInputElement, ComponentPropsWithoutRef<'input'> & InputProps>(function Input(
  { label, labelClassName, children, error, ...props },
  ref
) {
  return (
    <div>
      {!!label && <div className={c('inp-label text-p350 mb-5', labelClassName)}>{label}</div>}

      <label className='group relative block overflow-hidden'>
        <input
          className='w-full border-none bg-transparent px-7 py-4 placeholder-grayscale-250 outline-none transition-opacity'
          ref={ref}
          {...props}
        />
        {children}
      </label>
      {error && <div className='text-p450 pl-4 pt-2 text-base-red'>{errorToString(error)}</div>}
    </div>
  )
})

export default StylelessInput
