import { FC, useEffect, useRef } from 'react'
import { HistoryTargetType, Kind, useHistoryQuery } from '../../graphql/schema.tsx'
import DealCreatedEvent from './Events/DealEvent/DealCreated.tsx'
import DealChangedEvent from './Events/DealEvent/DealChanged.tsx'
import GuarantorCreatedEvent from './Events/DealEvent/GuarantorCreated.tsx'
import ComissionRewardCreatedEvent from './Events/DealEvent/ComissionRewardCreated.tsx'
import ApplicationCreatedEvent from './Events/ApplicationEvent/ApplicationCreated.tsx'
import ApplicationAssignedEvent from './Events/ApplicationEvent/ApplicationAssigned.tsx'
import ApplicationStatusUpdatedEvent from './Events/ApplicationEvent/ApplicationStatusUpdated.tsx'
import ReconciliationCreatedEvent from './Events/ApplicationEvent/ReconciliationCreated.tsx'
import ApplicationContactAddedEvent from './Events/ApplicationEvent/ApplicationContactAdded.tsx'
import ApplicationChangedEvent from './Events/ApplicationEvent/ApplicationChanged.tsx'
import VerificationStatusUpdatedEvent from './Events/ApplicationEvent/VerificationStatusUpdated.tsx'
import ReconciliationActivatedEvent from './Events/ApplicationEvent/ReconciliationActivated.tsx'
import ReconciliationCustomerStatusUpdatedEvent from './Events/ApplicationEvent/ReconciliationCustomerStatusUpdated.tsx'
import ContactChangedEvent from './Events/ApplicationEvent/ContactChangedEvent.tsx'
import { Card, CardHeader, CardTitle } from '../Card.tsx'
import SourceCreatedEvent from './Events/SourceEvent/SourceCreated.tsx'
import SourceChangedEvent from './Events/SourceEvent/SourceChanged.tsx'
import ScoringStatusUpdatedEvent from './Events/ApplicationEvent/ScoringStatusUpdated.tsx'
import ScoringCreatedEvent from './Events/ApplicationEvent/ScoringCreated.tsx'

interface HistoryProps {
  targetId: number
  targetType: HistoryTargetType
}

export interface BaseHistoryEventProps {
  actedAt: string
  initiatedByUserId?: number
  withLine: boolean
}

const History: FC<HistoryProps> = ({ targetId, targetType }) => {
  const { data } = useHistoryQuery({
    variables: {
      input: {
        targetId: targetId.toString(),
        targetType
      }
    },
    skip: !targetId || !targetType
  })
  const events = data?.history

  const elementScrollEvents = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (elementScrollEvents.current) {
      elementScrollEvents.current.scrollTop = elementScrollEvents.current.scrollHeight
    }
  }, [events])

  return (
    <Card>
      <CardHeader>
        <CardTitle>История</CardTitle>
      </CardHeader>
      {!!events?.length && (
        <div className='px-5 pb-5'>
          <div className='rounded-md bg-surface-primary shadow-card'>
            <div className='flex max-h-[485px] flex-col gap-1 overflow-y-auto p-6' ref={elementScrollEvents}>
              {events?.map((event, index, array) => {
                switch (targetType) {
                  case HistoryTargetType.Application:
                    switch (event.kind) {
                      case Kind.ApplicationCreated:
                        return (
                          <ApplicationCreatedEvent
                            actedAt={event.actedAt}
                            initiatedByUserId={event.initiatedByUserId}
                            withLine={array.length !== index + 1}
                          />
                        )
                      case Kind.ApplicationAssigned:
                        return (
                          <ApplicationAssignedEvent
                            data={event.data}
                            actedAt={event.actedAt}
                            initiatedByUserId={event.initiatedByUserId}
                            withLine={array.length !== index + 1}
                          />
                        )
                      case Kind.ApplicationStatusUpdated:
                        return (
                          <ApplicationStatusUpdatedEvent
                            data={event.data}
                            actedAt={event.actedAt}
                            initiatedByUserId={event.initiatedByUserId}
                            withLine={array.length !== index + 1}
                          />
                        )
                      case Kind.ReconciliationCreated:
                        return (
                          <ReconciliationCreatedEvent
                            data={event.data}
                            actedAt={event.actedAt}
                            initiatedByUserId={event.initiatedByUserId}
                            withLine={array.length !== index + 1}
                          />
                        )
                      case Kind.ApplicationContactAdded:
                        return (
                          <ApplicationContactAddedEvent
                            data={event.data}
                            actedAt={event.actedAt}
                            initiatedByUserId={event.initiatedByUserId}
                            withLine={array.length !== index + 1}
                          />
                        )
                      case Kind.ContactChanged:
                        return (
                          <ContactChangedEvent
                            data={event.data}
                            actedAt={event.actedAt}
                            initiatedByUserId={event.initiatedByUserId}
                            withLine={array.length !== index + 1}
                          />
                        )
                      case Kind.ApplicationChanged:
                        return (
                          <ApplicationChangedEvent
                            data={event.data}
                            actedAt={event.actedAt}
                            initiatedByUserId={event.initiatedByUserId}
                            withLine={array.length !== index + 1}
                          />
                        )
                      case Kind.VerificationStatusUpdated:
                        return (
                          <VerificationStatusUpdatedEvent
                            data={event.data}
                            actedAt={event.actedAt}
                            initiatedByUserId={event.initiatedByUserId}
                            withLine={array.length !== index + 1}
                          />
                        )
                      case Kind.ReconciliationActivated:
                        return (
                          <ReconciliationActivatedEvent
                            data={event.data}
                            actedAt={event.actedAt}
                            initiatedByUserId={event.initiatedByUserId}
                            withLine={array.length !== index + 1}
                          />
                        )
                      case Kind.ReconciliationCustomerStatusUpdated:
                        return (
                          <ReconciliationCustomerStatusUpdatedEvent
                            data={event.data}
                            actedAt={event.actedAt}
                            initiatedByUserId={event.initiatedByUserId}
                            withLine={array.length !== index + 1}
                          />
                        )
                      case Kind.ScoringProcessCreated:
                        return (
                          <ScoringCreatedEvent
                            data={event.data}
                            actedAt={event.actedAt}
                            initiatedByUserId={event.initiatedByUserId}
                            withLine={array.length !== index + 1}
                          />
                        )
                      case Kind.ScoringProcessChanged:
                        return (
                          <ScoringStatusUpdatedEvent
                            data={event.data}
                            actedAt={event.actedAt}
                            initiatedByUserId={event.initiatedByUserId}
                            withLine={array.length !== index + 1}
                          />
                        )
                    }
                    break
                  case HistoryTargetType.Deal:
                    switch (event.kind) {
                      case Kind.DealCreated:
                        return (
                          <DealCreatedEvent
                            key={event.id}
                            data={event.data}
                            actedAt={event.actedAt}
                            initiatedByUserId={event.initiatedByUserId}
                            withLine={array.length !== index + 1}
                          />
                        )
                      case Kind.DealChanged:
                        return (
                          <DealChangedEvent
                            key={event.id}
                            data={event.data}
                            actedAt={event.actedAt}
                            initiatedByUserId={event.initiatedByUserId}
                            withLine={array.length !== index + 1}
                          />
                        )
                      case Kind.GuarantorCreated:
                        return (
                          <GuarantorCreatedEvent
                            key={event.id}
                            data={event.data}
                            actedAt={event.actedAt}
                            initiatedByUserId={event.initiatedByUserId}
                            withLine={array.length !== index + 1}
                          />
                        )
                      case Kind.AgentCommissionRewardCreated:
                        return (
                          <ComissionRewardCreatedEvent
                            key={event.id}
                            data={event.data}
                            actedAt={event.actedAt}
                            initiatedByUserId={event.initiatedByUserId}
                            withLine={array.length !== index + 1}
                          />
                        )
                    }
                    break
                  case HistoryTargetType.Source:
                    switch (event.kind) {
                      case Kind.SourceCreated:
                        return (
                          <SourceCreatedEvent
                            key={event.id}
                            data={event.data}
                            actedAt={event.actedAt}
                            initiatedByUserId={event.initiatedByUserId}
                            withLine={array.length !== index + 1}
                          />
                        )
                      case Kind.SourceChanged:
                        return (
                          <SourceChangedEvent
                            key={event.id}
                            data={event.data}
                            actedAt={event.actedAt}
                            initiatedByUserId={event.initiatedByUserId}
                            withLine={array.length !== index + 1}
                          />
                        )
                    }
                }
              })}
            </div>
          </div>
        </div>
      )}
    </Card>
  )
}

export default History
