import { FC, ReactNode } from 'react'
import { dateTimeFormatter } from '../../../utils/dateFormatter.ts'
import Tooltip from '../../../components/Tooltip.tsx'
import { useUserByIdQuery } from '../../../graphql/schema.tsx'
import getFullName from '../../../utils/getFullName.ts'

interface PaymentScheduleTitleTooltipProps {
  target: ReactNode
  date: string
  userId?: string
}

const PaymentScheduleTitleTooltip: FC<PaymentScheduleTitleTooltipProps> = ({ target, userId, date }) => {
  const { data } = useUserByIdQuery({
    variables: {
      id: `${userId}`
    },
    skip: !userId
  })

  return (
    <Tooltip target={target}>
      Создал(а): {getFullName(data?.user, true)} {dateTimeFormatter.format(new Date(date))}
    </Tooltip>
  )
}

export default PaymentScheduleTitleTooltip
