import { TaskTargetType } from '../graphql/schema'

export const taskTargetLinkDict: Record<TaskTargetType, string> = {
  [TaskTargetType.Application]: 'applications',
  [TaskTargetType.Source]: 'sources',
  [TaskTargetType.Deal]: 'deals',
  [TaskTargetType.Supplier]: 'suppliers',
  [TaskTargetType.Customer]: 'customers',
  [TaskTargetType.ScoringProcess]: 'scoringProcess',
  [TaskTargetType.Collection]: 'collection'
}

export const getEntityLink = (targetType: TaskTargetType, targetId: number) => {
  return `/${taskTargetLinkDict[targetType]}/${targetId}`
}
