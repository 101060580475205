import { forwardRef, ComponentPropsWithoutRef, useRef } from 'react'

const StylelessTextAreaInput = forwardRef<HTMLTextAreaElement, ComponentPropsWithoutRef<'textarea'>>(function Textarea(
  { onChange, children, ...props },
  refEl: any
) {
  const ref = useRef<HTMLTextAreaElement>()

  const setTextareaHeight = (element: HTMLTextAreaElement) => {
    if (element) {
      element.style.height = '47px'
      element.style.height = element.scrollHeight + 'px'
    }
  }

  return (
    <label className='relative flex'>
      <textarea
        {...props}
        className='inset-0 h-full w-full resize-none border-0 bg-transparent px-7 py-6 outline-none focus:border-0 focus:ring-0'
        ref={(el: HTMLTextAreaElement) => {
          ref.current = el
          refEl && refEl(el)
          setTextareaHeight(el)
        }}
        onChange={(e) => {
          onChange && onChange(e)
          setTextareaHeight(e.currentTarget)
        }}
      />
      {children}
    </label>
  )
})

export default StylelessTextAreaInput
