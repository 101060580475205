import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ReactComponent as WordIcon } from '../../../svg/icons/word.svg'
import { ReactComponent as ChevronDownIcon } from '../../../svg/ui/chevron-down.svg'
import {
  ScoringProcessStatus,
  useApplicationForScoringConclusionQuery,
  useApplicationQuestionnaireQuery,
  useCreateConclusionDocxMutation,
  useCreateScoringProcessMutation,
  useScoringProcessQuery,
  useUpdateApplicationQuestionnaireMutation,
  useUpdateScoringProcessMutation,
  useUserByIdQuery
} from '../../../graphql/schema.tsx'
import {
  SCORING_GOVERNMENT_CONTRACTS_QUESTIONS,
  SCORING_LITIGATION_AND_LEASING_QUESTIONS,
  SCORING_STATUTORY_QUESTIONS,
  SCORING_TAX_QUESTIONS
} from '../../../utils/constants.ts'
import ScoringQuestion from './ScoringQuestion.tsx'
import ScoringInput from './ScoringInput.tsx'
import useScoringConclusion from './useScoringConclusion.ts'
import { ReactComponent as LoadingIcon } from '../../../svg/ui/refresh.svg'
import { ReactComponent as AppScoringStatusPendingIcon } from '../../../svg/icons/appScoringStatusPending.svg'
import { ReactComponent as AppScoringRisksUserEditIcon } from '../../../svg/icons/appScoringRisksUserEdit.svg'
import ScoringStatusSwitcher from './StatusSwitcher.tsx'
import SuspenseModal from '../../../components/SuspenseModal.tsx'
import ScoringRisksUserForm from './ScoringRisksUserForm.tsx'
import Avatar from '../../../components/Avatar'
import getFullName from '../../../utils/getFullName.ts'
import downloadFile from '../../../utils/downloadFileServerless.ts'
import { CompanyData, Okved } from '../../../types/dadata.ts'
import { capitalizeFirst, capitalizeFirstInWords, getCompanyForm, isIP } from '../../../utils/contractUtils.ts'
import { showPopup } from '../../../components/Toaster/showPopup.tsx'
import { formatMoney } from '../../../utils/formatNumber.ts'
import { dateFormatter, dateTimeFormatter } from '../../../utils/dateFormatter.ts'
import useNodes from '../../../hooks/useNodes.ts'
import { Card, CardAccentButton, CardHeader, CardMenu } from '../../../components/Card.tsx'
import useChangeStatus from './useChangeStatus.ts'
import WaitingForAdditionalInfoForm from './WaitingForAdditionalInfoForm.tsx'
import BankStatements from './BankStatements.tsx'
import Underwriting from './Underwriting.tsx'
import c from 'clsx'

const Scoring: FC = () => {
  const { id } = useParams<'id'>()

  const [isTabOpen, setIsTabOpen] = useState(() => {
    const stored = sessionStorage.getItem(`app-scoring-tab-${id}`)
    return stored === null ? true : stored === 'true'
  })

  const [changeStatus, loading] = useChangeStatus(parseInt(`${id}`))

  const [questionnaireData, setQuestionnaireData] = useState()

  const {
    data,
    loading: appQuestionnaireLoading,
    refetch: appQuestionnaireRefetch
  } = useApplicationQuestionnaireQuery({
    variables: {
      applicationId: parseInt(`${id}`)
    },
    skip: !id
  })
  const questionnaire = data?.applicationQuestionnaire

  useEffect(() => {
    if (questionnaire) {
      setQuestionnaireData(questionnaire?.data)
    }
  }, [questionnaire])

  const [conclusion, setConclusion, generateConclusion] = useScoringConclusion(questionnaire?.conclusion)

  const [updateApplicationQuestionnaireConclusion] = useUpdateApplicationQuestionnaireMutation()

  const onConclusionAccept = useCallback(
    async (value: string) => {
      if (!id) return

      await updateApplicationQuestionnaireConclusion({
        variables: {
          input: {
            applicationId: parseInt(id),
            conclusion: value
          }
        }
      })
    },
    [id, updateApplicationQuestionnaireConclusion]
  )

  const [editRisksUserId, setEditRisksUserId] = useState<number>()
  const [formOpen, setFormOpen] = useState(false)

  const {
    data: scoringProcessData,
    loading: scoringProcessLoading,
    refetch
  } = useScoringProcessQuery({
    variables: {
      applicationId: parseInt(`${id}`)
    },
    skip: !id
  })
  const scoringProcess = scoringProcessData?.scoringProcess

  const { data: userData } = useUserByIdQuery({
    variables: { id: scoringProcess?.risksUserId?.toString() as string },
    skip: !scoringProcess?.risksUserId
  })
  const risksUser = userData?.user

  const [createScoringProcess, { loading: createScoringProcessLoading }] = useCreateScoringProcessMutation()
  const [updateScoringProcess, { loading: updateScoringProcessLoading }] = useUpdateScoringProcessMutation()
  const [createConclusion] = useCreateConclusionDocxMutation()

  const [loadingSavingConclusion, setLoadingSavingConclusion] = useState(false)

  const { data: applicationData } = useApplicationForScoringConclusionQuery({
    variables: { id: `${id}` },
    skip: !id
  })
  const application = applicationData?.application
  const customerCompany = application?.customerCompany

  const customerCompanyDadata: CompanyData = useMemo(
    () => (customerCompany?.dadata ? customerCompany.dadata.data : {}),
    [customerCompany?.dadata]
  )
  const IP: boolean = isIP(customerCompanyDadata)

  const questionnaireChaptersFirst = [
    { value: 'Уставные', questions: SCORING_STATUTORY_QUESTIONS },
    { value: 'Госконтракты', questions: SCORING_GOVERNMENT_CONTRACTS_QUESTIONS }
  ]
  const questionnaireChaptersSecond = [
    {
      value: 'Налоговые',
      questions: IP
        ? SCORING_TAX_QUESTIONS.filter(
            (q) =>
              ![
                'Указать среднесписочное количество сотрудников организации?',
                'Специальный налоговый режим не применяется?'
              ].includes(q.value)
          )
        : SCORING_TAX_QUESTIONS
    },
    { value: 'Судебные и лизинговые', questions: SCORING_LITIGATION_AND_LEASING_QUESTIONS }
  ]

  const categories = useNodes(application?.leasingSubjectCategories?.edges)

  const [updateApplicationQuestionnaireData] = useUpdateApplicationQuestionnaireMutation()

  const handleDataSave = async (data: any) => {
    await updateApplicationQuestionnaireData({
      variables: {
        input: {
          applicationId: parseInt(`${id}`),
          data
        }
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateApplicationQuestionnaire: {
          __typename: 'ApplicationQuestionnaire',
          id: `${questionnaire?.id}`,
          applicationId: parseInt(`${id}`),
          data,
          conclusion: questionnaire?.conclusion || ''
        }
      }
    })
    setQuestionnaireData(data)
    const generatedConclusion = generateConclusion(data)
    await onConclusionAccept(generatedConclusion)
    appQuestionnaireRefetch()
  }

  const saveConclusion = useCallback(async () => {
    if (loadingSavingConclusion) return

    setLoadingSavingConclusion(true)

    const companyName = IP
      ? capitalizeFirstInWords(customerCompanyDadata?.name?.full)
      : capitalizeFirst(customerCompanyDadata?.name?.full)

    const okveds: Okved[] | undefined = customerCompanyDadata?.okveds
    const mainOkved: Okved | undefined = okveds?.find((o) => o?.code === customerCompanyDadata?.okved)

    const items = [
      { title: 'ИНН', value: customerCompany?.inn || '' },
      {
        title: 'Дата Регистрации',
        value: customerCompanyDadata?.state?.registration_date
          ? dateFormatter.format(new Date(customerCompanyDadata.state.registration_date))
          : ''
      }
    ]
    if (!IP) {
      items.push(
        { title: 'Уставный капитал', value: formatMoney(customerCompanyDadata?.capital?.value || 0) },
        { title: 'ГД', value: customerCompanyDadata?.management?.name },
        {
          title: 'Учредители',
          value:
            customerCompanyDadata?.founders
              ?.map(
                (f) =>
                  `${f?.type === 'PHYSICAL' ? capitalizeFirstInWords(f?.fio?.source || '') : f?.name} ${f?.share?.value}%`
              )
              ?.join(', ') || ''
        }
      )
    }
    items.push(
      { title: 'ОКВЭД', value: mainOkved?.name || '' },
      { title: 'Предмет лизинга', value: categories.map((c) => c.name).join(', ') }
    )

    const companyFounders = IP
      ? [{ title: 'Физ лицо' }]
      : customerCompanyDadata?.founders?.map((f) => ({
          title: `Учредитель ${f?.type === 'PHYSICAL' ? capitalizeFirstInWords(f?.fio?.source || '') : f?.name} ${f?.share?.value}%`
        })) || [{ title: 'Учредитель' }]
    if (!IP) {
      companyFounders.unshift({ title: `Генеральный директор ${customerCompanyDadata?.management?.name}` })
    }

    try {
      const createConclusionResult = await createConclusion({
        variables: {
          input: {
            companyOPF: customerCompany?.shortWithOpf ? getCompanyForm(customerCompany.shortWithOpf, companyName) : '',
            companyName,
            items,
            conclusion,
            companyFounders
          }
        }
      })

      const createConclusionUrl = createConclusionResult?.data?.createConclusion?.url
      if (!createConclusionUrl) throw new Error('Не удалось получить ссылку на файл')

      await downloadFile(createConclusionUrl, `Заключение (${customerCompany?.inn}) (#${id}).docx`)
    } catch (e: unknown) {
      if (e instanceof Error) {
        showPopup({ title: 'Ошибка сохранения', subtitle: e.message })
      }
    } finally {
      setLoadingSavingConclusion(false)
    }
  }, [
    loadingSavingConclusion,
    IP,
    customerCompany,
    categories,
    createConclusion,
    conclusion,
    id,
    customerCompanyDadata
  ])

  const handleCreateScoring = () => {
    createScoringProcess({
      variables: {
        applicationId: parseInt(`${id}`)
      }
    })
      .then(() => refetch())
      .catch((error) => {
        if (error.message.includes('Internal server error')) {
          showPopup({
            title: 'Ошибка',
            subtitle: 'Процесс скоринга уже запущен!'
          })
          refetch()
        } else {
          console.error(error)
        }
      })
  }

  return (
    <div className='flex flex-col gap-6'>
      <Card>
        {!scoringProcessLoading && (
          <CardHeader>
            {scoringProcess ? (
              <div
                className='flex w-full cursor-pointer items-center justify-between'
                onClick={() =>
                  setIsTabOpen((prev) => {
                    sessionStorage.setItem(`app-scoring-tab-${id}`, `${!prev}`)
                    return !prev
                  })
                }
              >
                <CardMenu>
                  <ScoringStatusSwitcher
                    status={scoringProcess?.status}
                    loading={loading}
                    onChangeStatus={async (status) => {
                      if (status === ScoringProcessStatus.WaitingForAdditionalInfo) {
                        setFormOpen(true)
                        return
                      }

                      await changeStatus(status as ScoringProcessStatus)
                      await refetch()
                    }}
                  />
                </CardMenu>
                <CardMenu className='ml-4'>
                  <CardAccentButton
                    className='py-3'
                    onClick={(e) => {
                      e.stopPropagation()
                      setEditRisksUserId(scoringProcess?.risksUserId)
                    }}
                  >
                    <Avatar userId={scoringProcess?.risksUserId} height='h-10' width='w-10' />
                    {getFullName(risksUser)}
                    <AppScoringRisksUserEditIcon />
                  </CardAccentButton>
                </CardMenu>

                <span className='ml-auto mr-2 font-medium text-labels-primary'>
                  Скоринг-анализ от{' '}
                  {scoringProcess?.createdDate && dateTimeFormatter.format(new Date(scoringProcess.createdDate))}
                </span>
                <div className='p-4 text-labels-tertiary'>
                  <ChevronDownIcon className={c('transition-transform', isTabOpen && '-rotate-180')} />
                </div>
              </div>
            ) : (
              <CardMenu>
                <CardAccentButton type='button' onClick={handleCreateScoring}>
                  {createScoringProcessLoading ? (
                    <LoadingIcon className='w-8 animate-spin' />
                  ) : (
                    <AppScoringStatusPendingIcon />
                  )}
                  Запустить скоринг
                </CardAccentButton>
              </CardMenu>
            )}
          </CardHeader>
        )}

        {isTabOpen && (
          <div className='px-5 pb-5 first:pt-5'>
            <div className='flex flex-col gap-6'>
              <div className='flex gap-12 rounded-md bg-surface-primary p-10 shadow-card'>
                <h2 className='font-display text-h200 font-medium'>Вывод:</h2>

                <div className='w-full'>
                  <ScoringInput
                    loading={appQuestionnaireLoading}
                    autoComplete='off'
                    onChange={(e) => setConclusion(e.currentTarget.value)}
                    onAccept={() => onConclusionAccept(conclusion)}
                    value={conclusion}
                  />
                </div>
              </div>

              {[ScoringProcessStatus.WaitingForAdditionalInfo, ScoringProcessStatus.InProgress].includes(
                scoringProcess?.status as ScoringProcessStatus
              ) && (
                <CardMenu>
                  <CardAccentButton className='py-3' onClick={saveConclusion}>
                    {loadingSavingConclusion ? (
                      <LoadingIcon className='h-8 w-8 animate-spin text-labels-secondary' />
                    ) : (
                      <WordIcon className='h-8 w-8 flex-none' />
                    )}
                    Скачать шаблон заключения
                  </CardAccentButton>
                </CardMenu>
              )}

              <div className='grid grid-cols-3 gap-6'>
                <div className='col-span-1 flex flex-col gap-6'>
                  {questionnaireChaptersFirst.map((chapter) => (
                    <div key={chapter.value}>
                      <div className='rounded-md bg-surface-primary p-10 shadow-card'>
                        <h2 className='font-display text-h200 font-medium'>{chapter.value}</h2>

                        <div className='mt-10 flex flex-col gap-10'>
                          {chapter.questions.map((question) => (
                            <ScoringQuestion
                              key={chapter.value + question.value}
                              loading={appQuestionnaireLoading}
                              chapter={chapter.value}
                              question={question}
                              questionnaireData={questionnaireData}
                              children={question?.children}
                              onDataChange={(data) => setQuestionnaireData(data)}
                              onDataSave={handleDataSave}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {questionnaireChaptersSecond.map((chapter) => (
                  <div key={chapter.value} className='col-span-1'>
                    <div className='rounded-md bg-surface-primary p-10 shadow-card'>
                      <h2 className='font-display text-h200 font-medium'>{chapter.value}</h2>

                      <div className='mt-10 flex flex-col gap-10'>
                        {chapter.questions.map((question) => (
                          <ScoringQuestion
                            key={chapter.value + question.value}
                            loading={appQuestionnaireLoading}
                            chapter={chapter.value}
                            question={question}
                            questionnaireData={questionnaireData}
                            children={question?.children}
                            onDataChange={(data) => setQuestionnaireData(data)}
                            onDataSave={handleDataSave}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </Card>

      <Underwriting />

      <div className='grid grid-cols-3'>
        <BankStatements customerCompanyId={customerCompany?._id} />
      </div>

      <SuspenseModal open={!!editRisksUserId} setOpen={() => setEditRisksUserId(undefined)}>
        <div className='z-10 rounded-xl bg-white-0'>
          <ScoringRisksUserForm
            userId={scoringProcess?.risksUserId}
            loading={updateScoringProcessLoading}
            onDone={async (risksUserId) => {
              await updateScoringProcess({
                variables: {
                  input: {
                    applicationId: parseInt(`${id}`),
                    risksUserId
                  }
                }
              })
              setEditRisksUserId(undefined)
            }}
          />
        </div>
      </SuspenseModal>
      <SuspenseModal open={formOpen} setOpen={setFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <WaitingForAdditionalInfoForm
            applicationId={parseInt(`${id}`)}
            scoringProcessId={parseInt(`${scoringProcess?.id}`)}
            managerId={application?.user?._id}
            onDone={async () => {
              await changeStatus(ScoringProcessStatus.WaitingForAdditionalInfo)
              await refetch()
              setFormOpen(false)
            }}
          />
        </div>
      </SuspenseModal>
    </div>
  )
}

export default Scoring
