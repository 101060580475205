import { FC, ComponentProps } from 'react'
import c from 'clsx'

const PrimaryButton: FC<ComponentProps<'button'>> = ({ children, className, ...props }) => {
  return (
    <button
      className={c(
        'flex items-center gap-x-3 rounded-md bg-surface-primary px-5 py-3 font-medium text-labels-secondary shadow-card hover:text-labels-primary hover:shadow-card-hover active:shadow-card disabled:cursor-not-allowed',
        className
      )}
      {...props}
    >
      {children}
    </button>
  )
}

export default PrimaryButton
