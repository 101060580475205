import { FC } from 'react'
import { ReactComponent as TickIcon } from '../svg/icons/tick-xs.svg'
import { FieldError } from 'react-hook-form'
import { Signatory } from '../containers/Deal/models'
import Select from './Select.tsx'

interface SignatorySelectInputProps {
  selected: Signatory
  onChange: (signatory: Signatory) => void
  label?: string
  labelClassName?: string
  error?: string | FieldError
}

export const VakhromeevSignatory = {
  fio: 'Вахромеев Никита Евгеньевич',
  status: 'Генеральный директор',
  statusGenitiveWithName: 'Генерального директора Вахромеева Никиты Евгеньевича, действующего на основании Устава'
}
export const KimSignatory = {
  fio: 'Ким Наталья Георгиевна',
  status: 'Руководитель отдела контроля качества',
  statusGenitiveWithName:
    'Руководителя отдела контроля качества Ким Натальи Георгиевны, действующего на основании Доверенности №ДВР231227-01 от 27.12.2023',
  basis: 'На основании доверенности №ДВР231227-01 от 27.12.2023'
}

const signatories: Signatory[] = [VakhromeevSignatory, KimSignatory]
const signatoryDescDict: Record<string, string> = {
  [VakhromeevSignatory.fio]: 'Генеральный директор. На основании Устава',
  [KimSignatory.fio]: 'Руководитель отдела контроля качества. На основании Доверенности №ДВР231227-01 от 27.12.2023'
}
export const signatorySignatureDict: Record<string, string> = {
  [VakhromeevSignatory.fio]: 'Н. Е. Вахромеев',
  [KimSignatory.fio]: 'Н. Г. Ким'
}

const SignatorySelectInput: FC<SignatorySelectInputProps> = ({ selected, onChange, label, labelClassName, error }) => (
  <div className='relative'>
    <Select
      type='text'
      label={label || 'Выбрать подписанта'}
      labelClassName={labelClassName}
      placeholder='Выберите подписанта'
      value={selected?.fio}
      error={error}
      autoComplete='off'
    >
      {signatories?.map((signatory) => (
        <li
          key={signatory.fio}
          onClick={() => onChange(signatory)}
          className='cursor-pointer px-12 py-5 hover:bg-grayscale-450'
        >
          <div className='flex items-center justify-between'>
            <div>
              <div className='text-grayscale-0'>{signatory.fio}</div>
              <div className='mt-2 text-grayscale-150'>{signatoryDescDict[signatory.fio]}</div>
            </div>
            {selected?.fio === signatory.fio && (
              <div className='ml-5 flex items-center rounded-full bg-red-100/5 px-5 py-2 text-red-100'>
                <TickIcon />
              </div>
            )}
          </div>
        </li>
      ))}
    </Select>
  </div>
)

export default SignatorySelectInput
