import { FC } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as MoreIcon } from '../svg/icons/more.svg'

interface HintProps {
  text: string
  to: string
  onClick: () => void
}

const Hint: FC<RequireAtLeastOne<HintProps, 'onClick' | 'to'>> = ({ text, onClick, to }) => {
  const component = (
    <div className='flex items-center justify-between rounded-lg bg-tr-red px-7 py-4 font-medium text-base-red'>
      {text}
      <MoreIcon />
    </div>
  )
  if (onClick)
    return (
      <button className='block w-full text-left' onClick={onClick}>
        {component}
      </button>
    )
  if (to)
    return (
      <Link className='block' to={to}>
        {component}
      </Link>
    )
  return null
}

export default Hint
