import { FC } from 'react'
import { ReactComponent as EditIcon } from '../../../svg/icons/edit.svg'
import { ReactComponent as TickIcon } from '../../../svg/icons/tick-xs.svg'
import { SubmitHandler, useForm } from 'react-hook-form'
import SubmitButton from '../../../components/SubmitButton/index'
import {
  PledgeKind,
  useDealForPledgesSuspenseQuery,
  usePledgeHoldersQuery,
  useUpdateDealPledgesMutation
} from '../../../graphql/schema'
import useNodes from '../../../hooks/useNodes'
import RadioButtonsGroup from '../../../components/RadioButtonsGroup'
import Select from '../../../components/Select'
import { ApolloError } from '@apollo/client'
import { handleBackendErrorsToForm } from '../../../utils/backendErrorUtils'

interface FormProps {
  dealId: string
  onDone?: () => void
}

interface LeasingObjectPledgeFormInputs {
  pledgeHolder?: {
    _id: number
    name: string
    shortName?: string
    key?: string
  }
  pledgeKind: PledgeKind
  leaseObjectPledgeHolder?: {
    _id: number
    name: string
    shortName?: string
    key?: string
  }
  leaseObjectPledgeKind: PledgeKind
}

const LeasingObjectPledgeForm: FC<FormProps> = ({ dealId, onDone }) => {
  const { data: pledgeData } = useDealForPledgesSuspenseQuery({
    variables: { id: dealId }
  })

  const { handleSubmit, formState, setValue, watch, register, setError } = useForm<LeasingObjectPledgeFormInputs>({
    defaultValues: {
      pledgeKind: pledgeData?.deal?.pledgeKind,
      pledgeHolder: pledgeData?.deal?.pledgeHolder,
      leaseObjectPledgeKind: pledgeData?.deal?.leaseObjectPledgeKind,
      leaseObjectPledgeHolder: pledgeData?.deal?.leaseObjectPledgeHolder
    }
  })

  const { data: pledgeHoldersData } = usePledgeHoldersQuery()
  const pledgeHolders = useNodes(pledgeHoldersData?.pledgeHolders?.edges)

  const [updateDealPledges] = useUpdateDealPledgesMutation()

  const pledgeKind = watch('pledgeKind')
  const pledgeHolder = watch('pledgeHolder')

  const leaseObjectPledgeKind = watch('leaseObjectPledgeKind')
  const leaseObjectPledgeHolder = watch('leaseObjectPledgeHolder')

  const onSubmit: SubmitHandler<LeasingObjectPledgeFormInputs> = async (data) => {
    if (formState.isSubmitting) return

    await updateDealPledges({
      variables: {
        input: {
          id: dealId,
          pledgeKind: data.pledgeKind,
          leaseObjectPledgeKind: data.leaseObjectPledgeKind,
          pledgeHolder: data.pledgeKind === PledgeKind.WithPledge ? data.pledgeHolder?._id.toString() : undefined,
          leaseObjectPledgeHolder:
            data.leaseObjectPledgeKind === PledgeKind.WithPledge
              ? data.leaseObjectPledgeHolder?._id.toString()
              : undefined
        }
      }
    })
      .then(() => {
        if (onDone) onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<LeasingObjectPledgeFormInputs>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  return (
    <section className='md:w-[448px] p-12'>
      <h1 className='mb-12 text-h200 font-medium'>Добавление залога</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-12'>
          <div className='relative flex flex-col gap-6'>
            <RadioButtonsGroup
              onChange={(value) => setValue('pledgeKind', value)}
              options={[
                ['Да', PledgeKind.WithPledge],
                ['Нет', PledgeKind.WithoutPledge]
              ]}
              checkedValue={pledgeKind}
              label='Залог прав требования'
              labelClassName='font-medium'
            />
            {pledgeKind === PledgeKind.WithPledge && (
              <Select
                label='Залогодержатель'
                labelClassName='font-medium'
                type='text'
                editable={false}
                autoComplete='off'
                {...register('pledgeHolder')}
                value={pledgeHolder?.shortName}
                clearSelected={() => setValue('pledgeHolder', undefined)}
              >
                {pledgeHolders?.map((ph) => (
                  <li
                    key={ph?._id}
                    onClick={() => {
                      if (pledgeHolder?._id === ph?._id) {
                        setValue('pledgeHolder', undefined)
                      } else {
                        setValue('pledgeHolder', ph)
                      }
                    }}
                    className='cursor-pointer px-12 py-5 hover:bg-surface-secondary'
                  >
                    <div className='flex items-center justify-between'>
                      <div className='mb-1 text-base-black'>{ph?.shortName}</div>
                      {pledgeHolder?._id === ph?._id && (
                        <div className='ml-5 flex items-center rounded-full bg-base-red/5 px-5 py-2 text-base-red'>
                          <TickIcon />
                        </div>
                      )}
                    </div>
                  </li>
                ))}
              </Select>
            )}
            <RadioButtonsGroup
              onChange={(value) => setValue('leaseObjectPledgeKind', value)}
              options={[
                ['Да', PledgeKind.WithPledge],
                ['Нет', PledgeKind.WithoutPledge]
              ]}
              checkedValue={leaseObjectPledgeKind}
              label='Залог предмета лизинга'
              labelClassName='font-medium'
            />
            {leaseObjectPledgeKind === PledgeKind.WithPledge && (
              <Select
                label='Залогодержатель'
                labelClassName='font-medium'
                type='text'
                editable={false}
                autoComplete='off'
                {...register('leaseObjectPledgeHolder')}
                value={leaseObjectPledgeHolder?.shortName}
                clearSelected={() => setValue('leaseObjectPledgeHolder', undefined)}
              >
                {pledgeHolders?.map((ph) => (
                  <li
                    key={ph?._id}
                    onClick={() => {
                      if (leaseObjectPledgeHolder?._id === ph?._id) {
                        setValue('leaseObjectPledgeHolder', undefined)
                      } else {
                        setValue('leaseObjectPledgeHolder', ph)
                      }
                    }}
                    className='cursor-pointer px-12 py-5 hover:bg-surface-secondary'
                  >
                    <div className='flex items-center justify-between'>
                      <div className='mb-1 text-base-black'>{ph?.shortName}</div>
                      {leaseObjectPledgeHolder?._id === ph?._id && (
                        <div className='ml-5 flex items-center rounded-full bg-base-red/5 px-5 py-2 text-base-red'>
                          <TickIcon />
                        </div>
                      )}
                    </div>
                  </li>
                ))}
              </Select>
            )}
          </div>
        </div>
        <SubmitButton loading={formState.isSubmitting}>
          <EditIcon className='mr-5' />
          Изменить залог
        </SubmitButton>
      </form>
    </section>
  )
}

export default LeasingObjectPledgeForm
