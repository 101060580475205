import { FC, Fragment, useMemo, useState } from 'react'
import { ReactComponent as DownloadIcon } from '../../../svg/icons/fileDownload.svg'
import { ReactComponent as WarningIcon } from '../../../svg/ui/warning.svg'
import { ReactComponent as LoadingIcon } from '../../../svg/ui/refresh.svg'
import { useParams } from 'react-router-dom'
import useMultipleEntityDocuments from '../../../components/DocumentManager/useMultipleEntityDocuments'
import {
  DocumentTypeEntity,
  PaymentKind,
  PaymentTargetType,
  useDealForDealDocumentsQuery,
  usePaymentsQuery,
  useCreatePaymentScheduleNoticeDocxMutation,
  usePaymentsLazyQuery,
  useDealPaymentSchedulesQuery
} from '../../../graphql/schema'
import DocumentManager from '../../../components/DocumentManager'
import LeasingContractForm from './LeasingContractForm'
import CommissionContractForm from './CommissionContractForm'
import InvoiceForm from './InvoiceForm'
import Hint from '../../../components/Hint'
import {
  COMMISSION_REWARD_FOLDER_ACT_NAME,
  COMMISSION_REWARD_FOLDER_INVOICE_NAME,
  GUARANTOR_CONTRACT_FOLDER_NAME,
  SHIPPING_FOLDER_NAME,
  SPECIFICATIONS_FOLDER_NAME,
  SUPPLY_FOLDER_NAME,
  TRANSFER_FOLDER_NAME
} from '../../../config'
import Folder from '../../../components/DocumentManager/Folder'
import DocumentIdsFolder from '../../../components/DocumentManager/DocumentIdsFolder'
import dealFormOpenState from '../state'
import CollectionForm from './Collection/CollectionForm.tsx'
import SuspenseModal from '../../../components/SuspenseModal.tsx'
import GroupZip from '../../../components/DocumentManager/GroupZip.tsx'
import { entityTypeDict, paymentKindDict } from '../../../utils/dictionaries.ts'
import Columns from '../../../components/Columns.tsx'
import useAccessCheck from '../../../hooks/useAccessCheck.ts'
import { Card } from '../../../components/Card.tsx'
import AdditionalAgreementForm from './AdditionalAgreementForm.tsx'
import useNodes from '../../../hooks/useNodes.ts'
import {
  capitalizeFirst,
  capitalizeFirstInWords,
  getCompanyForm,
  getDateFormattedInDocumentName,
  isIP
} from '../../../utils/contractUtils.ts'
import downloadFile from '../../../utils/downloadFileServerless.ts'
import { CompanyData } from '../../../types/dadata.ts'
import { dateFormatter } from '../../../utils/dateFormatter.ts'
import { formatDecimal } from '../../../utils/formatNumber.ts'

const Documents: FC = () => {
  const { id } = useParams<'id'>()
  const dealId = parseInt(id || '')
  const [contractFormOpen, setContractFormOpen] = useState(false)
  const [commissionContractFormOpen, setCommissionContractFormOpen] = useState(false)
  const [additionalAgreementFormOpen, setAdditionalAgreementFormOpen] = useState(false)
  const [invoiceFormOpen, setInvoiceFormOpen] = useState(false)
  const [collectionFormOpen, setCollectionFormOpen] = useState(false)
  const [paymentScheduleNoticeSavingLoading, setPaymentScheduleNoticeSavingLoading] = useState(false)

  const hasAccessCollectionManage = useAccessCheck('deal.documents.collection.manage')

  const { data, loading } = useDealForDealDocumentsQuery({
    variables: { id: id as string },
    skip: !id,
    fetchPolicy: 'cache-and-network'
  })
  const deal = data?.deal

  const { data: leasingPaymentsData } = usePaymentsQuery({
    variables: {
      kind: PaymentKind.Leasing,
      targetType: PaymentTargetType.Deal,
      targetId: dealId.toString()
    },
    skip: !dealId
  })

  const payments = useNodes(leasingPaymentsData?.payments.edges)

  const { data: paymentSchedulesData } = useDealPaymentSchedulesQuery({
    variables: { dealId: dealId.toString() }
  })
  const paymentSchedules = paymentSchedulesData?.paymentSchedules || []
  const actualSchedule = paymentSchedules[0]

  const commissionRewardIds = (deal?.agentCommissionRewards?.edges?.map((r) => r?.node?._id) as number[]) || []
  const supplyIds = (deal?.dealSupplies?.edges?.map((ds) => ds?.node?._id) as number[]) || []
  const guarantorIds = (deal?.guarantors?.edges?.map((g) => g?.node?._id) as number[]) || []
  const suppliesSpecs = deal?.dealSupplies?.edges?.map((ds) => ds?.node?.nomenclature).filter(Boolean)
  const dealSupplies = useNodes(deal?.dealSupplies?.edges)

  const hasSupplies = !!deal?.dealSupplies?.edges?.length
  // all suppliers have same vat kind
  const suppliersVatKindOk =
    hasSupplies &&
    deal?.dealSupplies?.edges?.every((ds, _, arr) => {
      const vc = ds?.node?.supplierCompany?.vatKind
      return !!vc && vc === arr[0]?.node?.supplierCompany?.vatKind
    })

  const specsAdded = !!suppliesSpecs?.length && suppliesSpecs.length === deal?.dealSupplies?.edges?.length
  const hasBankDetails = deal?.customerCompany?.bankDetails?.totalCount
  const hasContractNumber = deal?.contractNumber
  const hasContractDate = deal?.contractDate
  const hasAdvancePaymentDate = deal?.advancePaymentDate
  const hasInsuranceKind = deal?.insuranceKind
  const hasPaidPayment = payments.some((payment) => payment.state === 'PAID')
  const hasLeasedObjectAddress = !!deal?.storageAddressesLeasedObject?.length
  const hasPaymentScheduleDocumentDate = actualSchedule?.documentDate
  const hasAllSuppliesShipped = dealSupplies.every((s) => s.shippingDone === true)

  const readyContract =
    hasSupplies &&
    hasBankDetails &&
    hasContractNumber &&
    hasContractDate &&
    hasAdvancePaymentDate &&
    hasInsuranceKind &&
    hasSupplies &&
    specsAdded &&
    suppliersVatKindOk &&
    hasLeasedObjectAddress
  const readyComissionContract = hasBankDetails
  const readyInvoice = hasBankDetails && hasContractNumber && hasContractDate && hasAdvancePaymentDate
  const readyCollection = hasContractNumber && hasContractDate
  const readyAdditionalAgreement =
    hasBankDetails &&
    hasSupplies &&
    specsAdded &&
    hasContractNumber &&
    hasContractDate &&
    hasPaidPayment &&
    hasAllSuppliesShipped
  const readyPaymentScheduleNotice = hasContractNumber && hasContractDate && hasPaymentScheduleDocumentDate

  const frameContractsIds = useMemo(() => {
    const result = new Set<number>(
      (deal?.dealSupplies?.edges?.map((ds) => ds?.node?.frameContractDocument?._id) as number[]) || []
    )
    return Array.from(result).filter(Boolean)
  }, [deal?.dealSupplies])

  const [commissionActDocs, commissionActDocsLoading] = useMultipleEntityDocuments({
    entityIds: commissionRewardIds,
    entityType: DocumentTypeEntity.AgentCommissionReward,
    folderName: COMMISSION_REWARD_FOLDER_ACT_NAME
  })

  const [commissionInvoiceDocs, commissionInvoiceDocsLoading] = useMultipleEntityDocuments({
    entityIds: commissionRewardIds,
    entityType: DocumentTypeEntity.AgentCommissionReward,
    folderName: COMMISSION_REWARD_FOLDER_INVOICE_NAME
  })

  const [supplyContractDocs, supplyDocsLoading] = useMultipleEntityDocuments({
    entityIds: supplyIds,
    entityType: DocumentTypeEntity.DealSupply,
    folderName: SUPPLY_FOLDER_NAME
  })

  const [supplySpecificationsDocs, supplySpecificationsDocsLoading] = useMultipleEntityDocuments({
    entityIds: supplyIds,
    entityType: DocumentTypeEntity.DealSupply,
    folderName: SPECIFICATIONS_FOLDER_NAME
  })

  const [guarantorDocs, guarantorDocsLoading] = useMultipleEntityDocuments({
    entityIds: guarantorIds,
    entityType: DocumentTypeEntity.Guarantor,
    folderName: GUARANTOR_CONTRACT_FOLDER_NAME
  })

  const [supplyShippingDocs, supplyShippingDocsLoading] = useMultipleEntityDocuments({
    entityIds: supplyIds,
    entityType: DocumentTypeEntity.DealSupply,
    folderName: SHIPPING_FOLDER_NAME
  })

  const [supplyTransferDocs, supplyTransferDocsLoading] = useMultipleEntityDocuments({
    entityIds: supplyIds,
    entityType: DocumentTypeEntity.DealSupply,
    folderName: TRANSFER_FOLDER_NAME
  })

  const shippingAndTransferDocs = [...supplyShippingDocs, ...supplyTransferDocs]
  const shippingAndTransferDocsLoading = supplyShippingDocsLoading || supplyTransferDocsLoading

  const [createPaymentScheduleNotice] = useCreatePaymentScheduleNoticeDocxMutation()
  const [getPayments] = usePaymentsLazyQuery()

  const customer = deal?.customerCompany
  const companyDadata: CompanyData = useMemo(() => (customer?.dadata ? customer.dadata.data : {}), [customer?.dadata])
  const IP: boolean = isIP(companyDadata)
  const companyName = IP ? capitalizeFirstInWords(companyDadata.name?.full) : capitalizeFirst(companyDadata.name?.full)

  const savePaymentScheduleNotice = async () => {
    if (paymentScheduleNoticeSavingLoading) return

    setPaymentScheduleNoticeSavingLoading(true)
    try {
      const { data: paymentsData } = await getPayments({
        variables: {
          kinds: [PaymentKind.Advance, PaymentKind.Leasing, PaymentKind.Redemption],
          targetType: PaymentTargetType.Deal,
          targetId: dealId.toString()
        }
      })
      const paymentsRaw =
        paymentsData?.payments?.edges
          ?.map((e) => e?.node)
          .sort((a, b) => {
            if (a?.kind === PaymentKind.Redemption && b?.kind !== PaymentKind.Redemption) {
              return 1
            } else if (b?.kind === PaymentKind.Redemption && a?.kind !== PaymentKind.Redemption) {
              return -1
            } else {
              return 0 // иначе сохраняем порядок
            }
          }) || []
      const leasingPayments = paymentsRaw?.filter((p) => p?.kind === PaymentKind.Leasing) || []

      if (deal?.vatRate === undefined) throw new Error('vatRate not found!')

      const payments = paymentsRaw?.map((p) => ({
        amount: typeof p?.amount === 'number' ? formatDecimal(p.amount * 100) : '',
        appreciationAmount: typeof p?.fee === 'number' ? formatDecimal(p.fee * 100) : '',
        date: p?.date ? dateFormatter.format(new Date(p.date)) : '',
        number:
          p?.kind === PaymentKind.Leasing
            ? `${leasingPayments?.findIndex((payment) => payment?.id === p.id) + 1}`
            : p?.kind && paymentKindDict[p.kind],
        redemptionPrice: typeof p?.redemption === 'number' ? formatDecimal(p.redemption * 100) : '',
        returnAmount: typeof p?.body === 'number' ? formatDecimal(p.body * 100) : '',
        vat: formatDecimal(((p?.amount || 0) / (100 + deal.vatRate)) * deal.vatRate * 100)
      }))
      const total = paymentsRaw?.reduce(
        (acc, item) => {
          const vat = ((item?.amount || 0) / (100 + deal.vatRate)) * deal.vatRate
          acc.amount += item?.amount || 0
          acc.appreciationAmount += item?.fee || 0
          acc.redemptionPrice += item?.redemption || 0
          acc.returnAmount += item?.body || 0
          acc.vat += vat
          return acc
        },
        {
          amount: 0,
          appreciationAmount: 0,
          redemptionPrice: 0,
          returnAmount: 0,
          vat: 0
        }
      )
      payments?.push({
        amount: formatDecimal(total.amount * 100),
        appreciationAmount: formatDecimal(total.appreciationAmount * 100),
        date: 'Итого',
        number: '',
        redemptionPrice: formatDecimal(total.redemptionPrice * 100),
        returnAmount: formatDecimal(total.returnAmount * 100),
        vat: formatDecimal(total.vat * 100)
      })

      const createPaymentScheduleNoticeResult = await createPaymentScheduleNotice({
        variables: {
          input: {
            companyName: `${customer?.shortWithOpf ? getCompanyForm(customer.shortWithOpf, companyName) : ''} ${IP ? companyName : '«' + companyName + '»'}`,
            companyAddress: companyDadata?.address?.unrestricted_value,
            companyInn: customer?.inn,
            contractNumber: deal?.contractNumber,
            contractDate: deal?.contractDate ? dateFormatter.format(new Date(deal.contractDate)) : '',
            documentDate: actualSchedule?.documentDate
              ? dateFormatter.format(new Date(actualSchedule.documentDate))
              : '',
            items: payments
          }
        }
      })

      const createPaymentScheduleNoticeUrl = createPaymentScheduleNoticeResult.data?.createPaymentScheduleNotice?.url
      if (!createPaymentScheduleNoticeUrl) throw new Error('Не удалось получить ссылку на файл')

      await downloadFile(
        createPaymentScheduleNoticeUrl,
        `${getDateFormattedInDocumentName(new Date())} Уведомление об изменении платежа ввиду изменения ключевой ставки №УВД${deal?.contractNumber} от ${dateFormatter.format(new Date())}.docx`
      )
    } catch (error) {
      console.error(error)
    } finally {
      setPaymentScheduleNoticeSavingLoading(false)
    }
  }

  return (
    <Fragment>
      <h3 className='mb-8 text-h200'>Инструменты</h3>
      <Card>
        <div className='mt-8 grid grid-cols-3 gap-5 p-5'>
          <Columns n={3}>
            <div className='mb-5'>
              <div className='rounded-md bg-white-0 px-8 py-8 shadow-xs'>
                <button
                  onClick={() => setContractFormOpen(true)}
                  disabled={!readyContract}
                  className='flex h-25 w-full items-center justify-center gap-5 rounded-md border-1 border-dashed border-grayscale-300 px-4 text-grayscale-250 hover:text-grayscale-200 disabled:hover:text-grayscale-250'
                >
                  {readyContract ? <DownloadIcon /> : <WarningIcon />}
                  Договор лизинга
                </button>
                {!readyContract && !loading && (
                  <div className='grid gap-y-2'>
                    <div className='px-8 pb-4 pt-6 text-grayscale-150'>Необходимо заполнить:</div>
                    {!hasBankDetails && (
                      <Hint
                        text='Банковские реквизиты компании'
                        to={`/customers/${data?.deal?.customerCompany?._id}`}
                      />
                    )}
                    {!hasSupplies && <Hint text='Поставки' to={`/deals/${id}/supplies`} />}
                    {!hasContractNumber && <Hint text='Номер договора' onClick={() => dealFormOpenState(true)} />}
                    {!hasContractDate && <Hint text='Дата договора' onClick={() => dealFormOpenState(true)} />}
                    {!hasAdvancePaymentDate && (
                      <Hint text='Дата внесения аванса' onClick={() => dealFormOpenState(true)} />
                    )}
                    {!hasInsuranceKind && <Hint text='Страховка' onClick={() => dealFormOpenState(true)} />}
                    {!specsAdded && <Hint text='Номенклатуры всех поставок' to={`/deals/${id}/supplies`} />}
                    {!suppliersVatKindOk && (
                      <Hint text='Один тип НДС у всех поставщиков' to={`/deals/${id}/supplies`} />
                    )}
                    {!hasLeasedObjectAddress && <Hint text='Адрес хранения предмета лизинга' to={`/deals/${id}/`} />}
                  </div>
                )}
                <SuspenseModal open={contractFormOpen} setOpen={setContractFormOpen}>
                  <div className='z-10 rounded-xl bg-white-0'>
                    <LeasingContractForm
                      dealId={dealId}
                      onDone={() => {
                        setContractFormOpen(false)
                      }}
                    />
                  </div>
                </SuspenseModal>
              </div>
            </div>

            <div className='mb-5'>
              <div className='rounded-md bg-white-0 px-8 py-8 shadow-xs'>
                <button
                  onClick={() => setCommissionContractFormOpen(true)}
                  disabled={!readyComissionContract}
                  className='flex h-25 w-full items-center justify-center gap-5 rounded-md border-1 border-dashed border-grayscale-300 px-4 text-grayscale-250 hover:text-grayscale-200 disabled:hover:text-grayscale-250'
                >
                  {readyComissionContract ? <DownloadIcon /> : <WarningIcon />}
                  Счёт-договор на комиссию
                </button>
                {!readyComissionContract && !loading && (
                  <div className='grid gap-y-2'>
                    <div className='px-8 pb-4 pt-6 text-grayscale-150'>Необходимо заполнить:</div>
                    {!hasBankDetails && (
                      <Hint
                        text='Банковские реквизиты компании'
                        to={`/customers/${data?.deal?.customerCompany?._id}`}
                      />
                    )}
                  </div>
                )}
                <SuspenseModal open={commissionContractFormOpen} setOpen={setCommissionContractFormOpen}>
                  <div className='z-10 rounded-xl bg-white-0'>
                    <CommissionContractForm
                      dealId={dealId}
                      onDone={() => {
                        setCommissionContractFormOpen(false)
                      }}
                    />
                  </div>
                </SuspenseModal>
              </div>
            </div>

            <div className='mb-5'>
              <div className='rounded-md bg-white-0 px-8 py-8 shadow-xs'>
                <button
                  onClick={() => setInvoiceFormOpen(true)}
                  disabled={!readyInvoice}
                  className='flex h-25 w-full items-center justify-center gap-5 rounded-md border-1 border-dashed border-grayscale-300 px-4 text-grayscale-250 hover:text-grayscale-200 disabled:hover:text-grayscale-250'
                >
                  {readyInvoice ? <DownloadIcon /> : <WarningIcon />}
                  Счёт на оплату
                </button>
                {!readyInvoice && !loading && (
                  <div className='grid gap-y-2'>
                    <div className='px-8 pb-4 pt-6 text-grayscale-150'>Необходимо заполнить:</div>
                    {!hasBankDetails && (
                      <Hint
                        text='Банковские реквизиты компании'
                        to={`/customers/${data?.deal?.customerCompany?._id}`}
                      />
                    )}
                    {!hasContractNumber && <Hint text='Номер договора' onClick={() => dealFormOpenState(true)} />}
                    {!hasContractDate && <Hint text='Дата договора' onClick={() => dealFormOpenState(true)} />}
                    {!hasAdvancePaymentDate && (
                      <Hint text='Дата внесения аванса' onClick={() => dealFormOpenState(true)} />
                    )}
                  </div>
                )}
                <SuspenseModal open={invoiceFormOpen} setOpen={setInvoiceFormOpen}>
                  <div className='z-10 rounded-xl bg-white-0'>
                    <InvoiceForm
                      dealId={dealId}
                      onDone={() => {
                        setInvoiceFormOpen(false)
                      }}
                    />
                  </div>
                </SuspenseModal>
              </div>
            </div>

            {hasAccessCollectionManage && (
              <div>
                <div className='rounded-md bg-white-0 px-8 py-8 shadow-xs'>
                  <button
                    onClick={() => setCollectionFormOpen(true)}
                    disabled={!readyCollection}
                    className='flex h-25 w-full items-center justify-center gap-5 rounded-md border-1 border-dashed border-grayscale-300 px-4 text-grayscale-250 hover:text-grayscale-200 disabled:hover:text-grayscale-250'
                  >
                    {readyCollection ? <DownloadIcon /> : <WarningIcon />}
                    Коллекторские дела
                  </button>
                  {!readyCollection && !loading && (
                    <div className='grid gap-y-2'>
                      <div className='px-8 pb-4 pt-6 text-grayscale-150'>Необходимо заполнить:</div>
                      {!hasContractNumber && <Hint text='Номер договора' onClick={() => dealFormOpenState(true)} />}
                      {!hasContractDate && <Hint text='Дата договора' onClick={() => dealFormOpenState(true)} />}
                    </div>
                  )}
                  <SuspenseModal open={collectionFormOpen} setOpen={setCollectionFormOpen}>
                    <div className='z-10 rounded-xl bg-white-0'>
                      <CollectionForm dealId={dealId} />
                    </div>
                  </SuspenseModal>
                </div>
              </div>
            )}

            <div>
              <div className='rounded-md bg-white-0 px-8 py-8 shadow-xs'>
                <button
                  onClick={() => setAdditionalAgreementFormOpen(true)}
                  disabled={!readyAdditionalAgreement}
                  className='flex h-25 w-full items-center justify-center gap-5 rounded-md border-1 border-dashed border-grayscale-300 px-4 text-grayscale-250 hover:text-grayscale-200 disabled:hover:text-grayscale-250'
                >
                  {readyAdditionalAgreement ? <DownloadIcon /> : <WarningIcon />}
                  Досрочный выкуп
                </button>
                {!readyAdditionalAgreement && !loading && (
                  <div className='grid gap-y-2'>
                    <div className='px-8 pb-4 pt-6 text-grayscale-150'>Необходимо заполнить:</div>
                    {!hasBankDetails && (
                      <Hint
                        text='Банковские реквизиты компании'
                        to={`/customers/${data?.deal?.customerCompany?._id}`}
                      />
                    )}
                    {!hasSupplies && <Hint text='Поставки' to={`/deals/${id}/supplies`} />}
                    {!hasAllSuppliesShipped && <Hint text='Завершить этап «Отгрузка»' to={`/deals/${id}/supplies`} />}
                    {!hasContractNumber && <Hint text='Номер договора' onClick={() => dealFormOpenState(true)} />}
                    {!hasContractDate && <Hint text='Дата договора' onClick={() => dealFormOpenState(true)} />}
                    {!specsAdded && <Hint text='Номенклатуры всех поставок' to={`/deals/${id}/supplies`} />}
                    {!hasPaidPayment && <Hint text='Оплатить хотя бы один платёж' to={`/deals/${id}/`} />}
                  </div>
                )}
                <SuspenseModal open={additionalAgreementFormOpen} setOpen={setAdditionalAgreementFormOpen}>
                  <div className='z-10 rounded-xl bg-white-0'>
                    <AdditionalAgreementForm
                      dealId={dealId}
                      onDone={() => {
                        setAdditionalAgreementFormOpen(false)
                      }}
                    />
                  </div>
                </SuspenseModal>
              </div>
            </div>

            <div>
              <div className='rounded-md bg-white-0 px-8 py-8 shadow-xs'>
                <button
                  onClick={savePaymentScheduleNotice}
                  disabled={!readyPaymentScheduleNotice || paymentScheduleNoticeSavingLoading}
                  className='flex h-25 w-full items-center justify-center gap-5 rounded-md border-1 border-dashed border-grayscale-300 px-4 text-grayscale-250 hover:text-grayscale-200 disabled:hover:text-grayscale-250'
                >
                  {paymentScheduleNoticeSavingLoading ? (
                    <LoadingIcon className='mr-2 animate-spin' width={20} height={20} />
                  ) : readyPaymentScheduleNotice ? (
                    <DownloadIcon />
                  ) : (
                    <WarningIcon />
                  )}
                  Уведомление об изменении графика
                </button>
                {!readyPaymentScheduleNotice && !loading && (
                  <div className='grid gap-y-2'>
                    <div className='px-8 pb-4 pt-6 text-grayscale-150'>Необходимо заполнить:</div>
                    {!hasContractNumber && <Hint text='Номер договора' onClick={() => dealFormOpenState(true)} />}
                    {!hasContractDate && <Hint text='Дата договора' onClick={() => dealFormOpenState(true)} />}
                    {!hasPaymentScheduleDocumentDate && <Hint text='“Дата документа” в графике' to={`/deals/${id}/`} />}
                  </div>
                )}
              </div>
            </div>
          </Columns>
        </div>
      </Card>

      <h3 className='my-8 text-h200'>Документы сделки</h3>
      <DocumentManager entityId={dealId} entityType={DocumentTypeEntity.Deal}>
        <div className='relative mb-5 rounded-xl bg-white-0 p-10 shadow-xs'>
          <div className='flex items-center justify-between gap-x-5 pb-12'>
            <h3 className='font-display text-h200'>Акты источника</h3>
            <GroupZip
              name={`${entityTypeDict[DocumentTypeEntity.Deal]} ${dealId} Акты источника`}
              ids={commissionActDocs.map((doc) => doc._id)}
            />
          </div>
          <Folder files={commissionActDocs} loading={commissionActDocsLoading} canDelete={false} />
        </div>
        <div className='relative mb-5 rounded-xl bg-white-0 p-10 shadow-xs'>
          <div className='flex items-center justify-between gap-x-5 pb-12'>
            <h3 className='font-display text-h200'>Счета-фактуры источника</h3>
            <GroupZip
              name={`${entityTypeDict[DocumentTypeEntity.Deal]} ${dealId} Счета-фактуры источника`}
              ids={commissionInvoiceDocs.map((doc) => doc._id)}
            />
          </div>
          <Folder files={commissionInvoiceDocs} loading={commissionInvoiceDocsLoading} canDelete={false} />
        </div>
        <div className='relative mb-5 rounded-xl bg-white-0 p-10 shadow-xs'>
          <div className='flex items-center justify-between gap-x-5 pb-12'>
            <h3 className='font-display text-h200'>Договоры поставки</h3>
            <GroupZip
              name={`${entityTypeDict[DocumentTypeEntity.Deal]} ${dealId} Договоры поставки`}
              ids={frameContractsIds}
            />
          </div>
          <DocumentIdsFolder fileIds={frameContractsIds} loading={supplyDocsLoading} canDelete={false} />
        </div>
        <div className='relative mb-5 rounded-xl bg-white-0 p-10 shadow-xs'>
          <div className='flex items-center justify-between gap-x-5 pb-12'>
            <h3 className='font-display text-h200'>Нерамочные договоры поставки</h3>
            <GroupZip
              name={`${entityTypeDict[DocumentTypeEntity.Deal]} ${dealId} Нерамочные договоры поставки`}
              ids={supplyContractDocs.map((doc) => doc._id)}
            />
          </div>
          <Folder files={supplyContractDocs} loading={supplyDocsLoading} canDelete={false} />
        </div>
        <div className='relative mb-5 rounded-xl bg-white-0 p-10 shadow-xs'>
          <div className='flex items-center justify-between gap-x-5 pb-12'>
            <h3 className='font-display text-h200'>Спецификации</h3>
            <GroupZip
              name={`${entityTypeDict[DocumentTypeEntity.Deal]} ${dealId} Спецификации`}
              ids={supplySpecificationsDocs.map((doc) => doc._id)}
            />
          </div>
          <Folder files={supplySpecificationsDocs} loading={supplySpecificationsDocsLoading} canDelete={false} />
        </div>
        <div className='relative mb-5 rounded-xl bg-white-0 p-10 shadow-xs'>
          <div className='flex items-center justify-between gap-x-5 pb-12'>
            <h3 className='font-display text-h200'>Договоры поручительства</h3>
            <GroupZip
              name={`${entityTypeDict[DocumentTypeEntity.Deal]} ${dealId} Договоры поручительства`}
              ids={guarantorDocs.map((doc) => doc._id)}
            />
          </div>
          <Folder files={guarantorDocs} loading={guarantorDocsLoading} canDelete={false} />
        </div>
        <div className='relative mb-5 rounded-xl bg-white-0 p-10 shadow-xs'>
          <div className='flex items-center justify-between gap-x-5 pb-12'>
            <h3 className='font-display text-h200'>Отгрузочные документы</h3>
            <GroupZip
              name={`${entityTypeDict[DocumentTypeEntity.Deal]} ${dealId} Отгрузочные документы`}
              ids={shippingAndTransferDocs.map((doc) => doc._id)}
            />
          </div>
          <Folder files={shippingAndTransferDocs} loading={shippingAndTransferDocsLoading} canDelete={false} />
        </div>
      </DocumentManager>

      <h3 className='my-8 text-h200'>Документы компании-клиента</h3>
      {deal?.customerCompany && (
        <DocumentManager entityId={deal?.customerCompany?._id} entityType={DocumentTypeEntity.CustomerCompany} />
      )}
    </Fragment>
  )
}

export default Documents
