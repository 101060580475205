import { FC } from 'react'
import { useDealPaymentScheduleSuspenseQuery, useSetPaymentScheduleDateMutation } from '../../../graphql/schema.tsx'
import Input from '../../../components/Input.tsx'
import { SubmitHandler, useForm } from 'react-hook-form'
import { handleBackendErrorsToForm } from '../../../utils/backendErrorUtils.ts'
import { ApolloError } from '@apollo/client'
import SubmitButton from '../../../components/SubmitButton'
import { dateFormatter } from '../../../utils/dateFormatter.ts'

type Inputs = {
  documentDate: string
}

interface PaymentScheduleDateFormProps {
  paymentScheduleId: string
  onDone?: () => void
}

const PaymentScheduleDateForm: FC<PaymentScheduleDateFormProps> = ({ paymentScheduleId, onDone }) => {
  const [updatePaymentScheduleDate, { loading }] = useSetPaymentScheduleDateMutation()
  const { data } = useDealPaymentScheduleSuspenseQuery({ variables: { id: paymentScheduleId } })
  const paymentSchedule = data?.paymentSchedule

  const { register, handleSubmit, setError } = useForm<Inputs>({
    defaultValues: {
      documentDate: paymentSchedule?.documentDate
        ? new Date(paymentSchedule?.documentDate).toISOString().split('T').shift()
        : ''
    }
  })

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (data.documentDate) {
      updatePaymentScheduleDate({
        variables: {
          input: {
            id: paymentScheduleId,
            documentDate: new Date(data.documentDate).toISOString()
          }
        }
      })
        .then(() => {
          if (onDone) onDone()
        })
        .catch((err: ApolloError) => {
          handleBackendErrorsToForm<Inputs>(err, (fieldPath, textError) => {
            setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
          })
        })
    } else {
      if (onDone) onDone()
    }
  }

  return (
    <section className='p-12 md:w-[448px]'>
      <h1 className='mb-12 font-display text-h200'>Редактирование даты документа</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-12 grid grid-cols-7 items-center gap-y-10'>
          <div className='col-span-3'>Дата документа</div>
          <div className='col-span-1' />
          <div className='col-span-3'>Новая дата</div>

          <div className='col-span-3'>
            {paymentSchedule?.documentDate
              ? dateFormatter.format(new Date(paymentSchedule.documentDate))
              : 'Не указана'}
          </div>
          <div className='col-span-1'>{`->`}</div>
          <div className='col-span-3'>
            <Input type='date' {...register('documentDate', { required: true })} />
          </div>
        </div>
        <SubmitButton loading={loading}>Применить изменения</SubmitButton>
      </form>
    </section>
  )
}

export default PaymentScheduleDateForm
