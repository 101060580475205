import { FC, useState } from 'react'
import EntityFolder from '../../../components/DocumentManager/EntityFolder'
import { ReactComponent as DownloadIcon } from '../../../svg/icons/fileDownload.svg'
import { ReactComponent as TrashIcon } from '../../../svg/ui/delete.svg'
import { ReactComponent as PlusIcon } from '../../../svg/ui/plus.svg'
import useNodes from '../../../hooks/useNodes'
import Modal from '../../../components/Modal'
import GuarantorForm from './GuarantorForm'
import { useParams } from 'react-router-dom'
import {
  DocumentTypeEntity,
  GuarantorStatus,
  useDealGuarantorsQuery,
  useRemoveGuarantorMutation
} from '../../../graphql/schema'
import EditGuarantorForm from './EditGuarantorForm'
import GuaranteeForm from './GuaranteeForm'
import ConfirmationForm from '../../../components/ConfirmationForm'
import GuarantorCompany from './GuarantorCompany'
import GuarantorPerson from './GuarantorPerson'
import Hint from '../../../components/Hint.tsx'
import dealFormOpenState from '../state.ts'
import SuspenseModal from '../../../components/SuspenseModal.tsx'
import { Card } from '../../../components/Card.tsx'

const Guarantors: FC = () => {
  const { id } = useParams<'id'>()
  const [formOpen, setFormOpen] = useState(false)

  const [guarantorForEditForm, setGuarantorForEditForm] = useState<any>()
  const [editFormOpen, setEditFormOpen] = useState(false)

  const [guarantorForGuaranteeForm, setGuarantorForGuaranteeForm] = useState<any>()
  const [guaranteeFormOpen, setGuaranteeFormOpen] = useState(false)

  const [deletionFormOpen, setDeletionFormOpen] = useState(false)
  const [deletingEntity, setDeletingEntity] = useState<{ _id: number }>()

  const { data, refetch } = useDealGuarantorsQuery({ variables: { id: id?.toString() as string }, skip: !id })

  const [removeGuarantor] = useRemoveGuarantorMutation({
    onCompleted: () => {
      refetch()
    }
  })

  function openDeletingEntityModal(entity?: { _id: number }): void {
    setDeletingEntity(entity)
    setDeletionFormOpen(true)
  }

  function remove() {
    if (!deletingEntity) {
      return
    }
    return removeGuarantor({
      variables: {
        input: {
          guarantor: deletingEntity._id.toString()
        }
      }
    })
  }

  const guarantors = useNodes(data?.deal?.guarantors?.edges)
  const deal = data?.deal

  if (!deal) return null

  return (
    <Card>
      <div className='grid auto-rows-fr grid-cols-3 gap-6 p-5'>
        {guarantors?.map((guarantor) => {
          const hasContractDate = !!deal?.contractDate
          const hasBankDetails = !guarantor?.contact ? !!guarantor.company?.bankDetails?.totalCount : true
          const canSaveContract = hasContractDate && hasBankDetails

          return (
            <div key={guarantor?._id}>
              <div className='rounded-md bg-white-0 shadow-xs'>
                {guarantor?.contact ? (
                  <GuarantorPerson
                    id={guarantor?._id}
                    contactId={guarantor?.contact?._id}
                    onEdit={() => {
                      setGuarantorForEditForm(guarantor)
                      setEditFormOpen(true)
                    }}
                    fio={guarantor?.contact?.fio}
                    phone={guarantor?.contact?.phone}
                    email={guarantor?.contact?.email}
                    dateCreated={guarantor?.created}
                    dateSigned={guarantor?.signed}
                    signMethod={guarantor?.signMethod}
                    status={guarantor?.status as GuarantorStatus}
                  />
                ) : (
                  <GuarantorCompany
                    id={guarantor?._id}
                    companyId={guarantor.company?._id}
                    onEdit={() => {
                      setGuarantorForEditForm(guarantor)
                      setEditFormOpen(true)
                    }}
                    name={guarantor?.company?.shortWithOpf}
                    inn={guarantor?.company?.inn}
                    registrationDate={guarantor?.company?.registrationDate}
                    dateCreated={guarantor?.created}
                    dateSigned={guarantor?.signed}
                    signMethod={guarantor?.signMethod}
                    status={guarantor?.status as GuarantorStatus}
                  />
                )}
                <div className='border-t-1 border-grayscale-400 px-10 py-6'>
                  <div className='mb-6 font-display font-medium'>Договор поручительства</div>
                  <EntityFolder
                    entityId={guarantor._id}
                    entityType={DocumentTypeEntity.Guarantor}
                    folderName='Договор поручительства'
                  />
                </div>
                <div className='border-t-1 border-grayscale-400 px-10 py-10'>
                  {canSaveContract && (
                    <div
                      onClick={() => {
                        setGuarantorForGuaranteeForm(guarantor)
                        setGuaranteeFormOpen(true)
                      }}
                      className='mb-6 flex cursor-pointer items-center text-grayscale-150'
                    >
                      <DownloadIcon className='w-14' />
                      <span className='ml-4'>Скачать договор</span>
                    </div>
                  )}
                  <div
                    onClick={() => openDeletingEntityModal(guarantor)}
                    className='flex cursor-pointer items-center text-red-150'
                  >
                    <TrashIcon className='w-14' />
                    <span className='ml-4'>Удалить поручителя</span>
                  </div>
                </div>
                {!canSaveContract && (
                  <div className='grid gap-y-2 border-t-1 border-grayscale-400 px-4 pb-4'>
                    <div className='px-6 py-6 text-grayscale-150'>Необходимо заполнить:</div>
                    {!hasContractDate && <Hint text='Дата договора лизинга' onClick={() => dealFormOpenState(true)} />}
                    {!hasBankDetails && (
                      <Hint text='Банковские реквизиты компании' to={`/guarantors/${guarantor.company?._id}`} />
                    )}
                  </div>
                )}
              </div>
            </div>
          )
        })}

        <div
          className='flex cursor-pointer flex-col items-center justify-center rounded-md border-1 border-dashed border-grayscale-300 py-25 text-grayscale-200'
          onClick={() => setFormOpen(true)}
        >
          <div>
            <PlusIcon className='h-15 w-15' />
          </div>
          <div className='mt-3'>Новый поручитель</div>
        </div>

        <Modal open={formOpen} setOpen={setFormOpen}>
          <div className='z-10 rounded-xl bg-white-0'>
            <GuarantorForm
              dealId={deal._id}
              onDone={() => {
                refetch()
                setFormOpen(false)
              }}
            />
          </div>
        </Modal>

        <Modal open={editFormOpen} setOpen={setEditFormOpen}>
          <div className='z-10 rounded-xl bg-white-0'>
            <EditGuarantorForm
              guarantor={guarantorForEditForm}
              onDone={() => {
                refetch()
                setEditFormOpen(false)
              }}
            />
          </div>
        </Modal>

        <SuspenseModal open={guaranteeFormOpen} setOpen={setGuaranteeFormOpen}>
          <div className='z-10 rounded-xl bg-white-0'>
            <GuaranteeForm
              dealId={id!}
              guarantorId={guarantorForGuaranteeForm?._id}
              onDone={() => {
                setGuaranteeFormOpen(false)
              }}
            />
          </div>
        </SuspenseModal>

        <Modal open={deletionFormOpen} setOpen={setDeletionFormOpen}>
          <div className='z-10 rounded-xl bg-white-0'>
            <ConfirmationForm
              title='Удаление поручителя'
              onDone={() => {
                setDeletionFormOpen(false)
                remove()
              }}
              onDismiss={() => setDeletionFormOpen(false)}
            >
              Вы действительно хотите удалить поручителя?
            </ConfirmationForm>
          </div>
        </Modal>
      </div>
    </Card>
  )
}

export default Guarantors
