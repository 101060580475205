import { FC, useCallback, useEffect } from 'react'
import { Card, CardHeader, CardTitle } from '../../components/Card'
import { useLeasedObjectAddressesQuery, useUpdateLeasedObjectAddressesMutation } from '../../graphql/schema'
import { ReactComponent as PlusIcon } from '../../svg/ui/plus.svg'
import { ReactComponent as TrashIcon } from '../../svg/ui/delete.svg'
import { useFieldArray, useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'
import StylelessTextAreaInput from '../../components/StylelessTextAreaInput'

interface LeasedObjectAddressProps {
  dealId: number
}

type AddressItem = {
  address: string
}

// не понимаю, почему не работает через addresses: string. почитал документацию, спросил у гпт, нифига не работает
interface LeasedObjectAddressValues {
  addresses: AddressItem[]
}

const LeasedObjectAddress: FC<LeasedObjectAddressProps> = ({ dealId }) => {
  const [updateLeasedObjectAddress] = useUpdateLeasedObjectAddressesMutation()

  const { data: leasedObjectAddressesData } = useLeasedObjectAddressesQuery({ variables: { id: dealId.toString() } })

  const leasedObjectAddresses = leasedObjectAddressesData?.deal?.storageAddressesLeasedObject

  const { register, control, setValue, getValues } = useForm<LeasedObjectAddressValues>()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'addresses'
  })

  useEffect(() => {
    if (!getValues('addresses')?.length && leasedObjectAddresses?.length) {
      setValue(
        'addresses',
        leasedObjectAddresses.map((v) => ({ address: v }))
      )
    }
  }, [leasedObjectAddresses, setValue, getValues])

  const updateAddresses = async () => {
    const addresses = getValues('addresses')

    await updateLeasedObjectAddress({
      variables: {
        input: {
          id: dealId.toString(),
          storageAddressesLeasedObject: addresses.map((a) => a.address)
        }
      }
    })
  }

  const debouncedUpdateAddresses = useCallback(debounce(500, updateAddresses), [updateAddresses])

  const handleAddressAdd = () => append({ address: '' })

  return (
    <Card>
      <CardHeader>
        <CardTitle>Адрес хранения предмета лизинга</CardTitle>
      </CardHeader>
      <div className='px-5 pb-5'>
        <div className='rounded-md bg-surface-primary shadow-card'>
          <table className='w-full'>
            <tbody>
              {fields.map((field, index) => (
                <tr key={field.id} className=''>
                  <td
                    className='cursor-text border-b-1 border-r border-grayscale-400 align-top focus-within:ring-1 focus-within:ring-inset focus-within:ring-base-red/75'
                    onClick={(e) => {
                      const td = e.currentTarget
                      const input = td.querySelector('textarea')
                      if (e.target === td && input) {
                        input.focus()
                      }
                    }}
                  >
                    <StylelessTextAreaInput
                      autoComplete='off'
                      {...register(`addresses.${index}.address`, {
                        onChange: debouncedUpdateAddresses
                      })}
                    />
                  </td>
                  <td className='border-b-1 border-grayscale-400'>
                    <button
                      type='button'
                      tabIndex={-1}
                      onClick={async () => {
                        remove(index)
                        await updateAddresses()
                      }}
                      className='flex w-full justify-center px-7 py-6 text-labels-tertiary hover:text-base-red'
                    >
                      <TrashIcon className='cursor-pointer' />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <button
            type='button'
            className='flex w-full items-center gap-4 px-7 py-6 text-body-s text-labels-tertiary hover:text-base-red'
            onClick={handleAddressAdd}
          >
            <PlusIcon />
            Добавить адрес
          </button>
        </div>
      </div>
    </Card>
  )
}

export default LeasedObjectAddress
