import { FC, useState } from 'react'
import { GridButton, GridTable, GridTableCell, GridTableRow } from '../../../components/Table'
import { ReactComponent as DeleteIcon } from '../../../svg/ui/delete.svg'
import { dateFormatter } from '../../../utils/dateFormatter'
import { formatMoneyDecimal } from '../../../utils/formatNumber'
import { DocumentTypeEntity, SpecItem, useDeleteShipmentMutation, UserRoles } from '../../../graphql/schema'
import { Card, CardIconButton, CardMenu } from '../../../components/Card'
import { ReactComponent as NomenclatureIcon } from '../../../svg/ui/agreement.svg'
import { ReactComponent as EditIcon } from '../../../svg/ui/edit.svg'
import ConfirmationForm from '../../../components/ConfirmationForm'
import Modal from '../../../components/Modal'
import { SHIPMENT_SPECS_FOLDER_NAME } from '../../../config'
import EntityFolder from '../../../components/DocumentManager/EntityFolder'
import Specification from '../../../components/Specification'
import useCurrentUser from '../../../hooks/useCurrentUser'
import SuspenseModal from '../../../components/SuspenseModal'
import ShipmentForm from './ShipmentForm'

interface SupplyShipmentProps {
  id: number
  index: number
  amount: number
  date?: string
  editable?: boolean
  nomenclature?: SpecItem[]
}

const SupplyShipment: FC<SupplyShipmentProps> = ({ index, amount, date, id, nomenclature, editable = true }) => {
  const [deleteShipment] = useDeleteShipmentMutation({
    update: (cache, result) => {
      const deletedId = result.data?.removeShipment?.shipment?._id
      if (!deletedId) return
      const normalizedId = cache.identify({ id: deletedId, __typename: 'Shipment' })
      cache.evict({ id: normalizedId })
      cache.gc()
    }
  })
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [specModalOpen, setSpecModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)

  const currentUser = useCurrentUser()
  const isAdmin = currentUser?.roles.includes(UserRoles.RoleAdmin)

  return (
    <div className=''>
      <GridTable className='grid-cols-[42%_58%] border-b-1 border-separators-primary'>
        <GridTableCell className='col-span-full flex items-center justify-between'>
          <div className='mr-auto font-medium'>Отгрузка №{index}</div>
          {editable && (
            <CardMenu>
              {isAdmin && (
                <CardIconButton onClick={() => setEditModalOpen(true)}>
                  <EditIcon />
                </CardIconButton>
              )}
              <CardIconButton
                onClick={() => {
                  setDeleteModalOpen(true)
                }}
              >
                <DeleteIcon />
              </CardIconButton>
            </CardMenu>
          )}
        </GridTableCell>
        <GridTableRow>
          <GridTableCell className='text-labels-secondary'>Сумма</GridTableCell>
          <GridTableCell>{formatMoneyDecimal(amount / 100)}</GridTableCell>
        </GridTableRow>
        <GridTableRow>
          <GridTableCell className='text-labels-secondary'>Дата приёмки</GridTableCell>
          <GridTableCell>{date && dateFormatter.format(new Date(date))}</GridTableCell>
        </GridTableRow>
        {!!nomenclature && (
          <GridTableRow>
            <GridTableCell className='text-labels-secondary'>Номенклатура</GridTableCell>
            <GridButton onClick={() => setSpecModalOpen(true)}>
              <NomenclatureIcon />
              Открыть
            </GridButton>
          </GridTableRow>
        )}
      </GridTable>

      <div className='p-5'>
        <EntityFolder
          entityId={id}
          canDelete={editable}
          canUpload={editable}
          entityType={DocumentTypeEntity.Shipment}
          folderName={SHIPMENT_SPECS_FOLDER_NAME}
        />
      </div>

      <Modal open={deleteModalOpen} setOpen={setDeleteModalOpen}>
        <div className='rounded-xl bg-surface-primary'>
          <ConfirmationForm
            title='Удалить отгрузку?'
            onDone={async () => {
              await deleteShipment({
                variables: {
                  input: { shipment: id.toString() }
                }
              })
              setDeleteModalOpen(false)
            }}
            onDismiss={() => setDeleteModalOpen(false)}
          >
            Это действие необратимо
          </ConfirmationForm>
        </div>
      </Modal>
      <SuspenseModal open={editModalOpen} setOpen={setEditModalOpen}>
        <div className='z-10 rounded-xl bg-surface-primary'>
          <ShipmentForm shipmentId={id} onDone={() => setEditModalOpen(false)} />
        </div>
      </SuspenseModal>
      <Modal open={specModalOpen} setOpen={setSpecModalOpen}>
        <section className='min-w-[500px] max-w-[900px] rounded-xl bg-surface-primary px-12 pb-12 pt-10'>
          <h1 className='mb-10 text-title-l font-medium'>Номенклатура</h1>
          <Card className='p-5'>
            <div className='rounded-md bg-surface-primary shadow-card'>
              <Specification items={nomenclature} currency='RUB' />
            </div>
          </Card>
        </section>
      </Modal>
    </div>
  )
}

export default SupplyShipment
