import { FC } from 'react'
import { BaseHistoryEventProps } from '../..'
import { EventActedAt, EventInitiatedBy } from '../EventComponents'

export interface ScoringCreatedEventEventProps extends BaseHistoryEventProps {
  data?: {
    scoringId: number
  }
}

const ScoringCreatedEvent: FC<ScoringCreatedEventEventProps> = ({ actedAt, withLine, initiatedByUserId }) => {
  return (
    <div className='flex gap-6'>
      <EventActedAt date={actedAt} withLine={withLine} />
      <div>
        <div className='flex items-start gap-2'>
          <EventInitiatedBy userId={initiatedByUserId} />
          <span className='text-labels-tertiary'>запустил(а) процесс "Скоринга"</span>
        </div>
        <div>В работе</div>
      </div>
    </div>
  )
}

export default ScoringCreatedEvent
