import { FC, useEffect, useMemo } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { ReactComponent as FileIcon } from '../../../svg/icons/fileDownload.svg'
import { AdditionalAgreementFormInputs } from '../models'
import {
  PaymentKind,
  PaymentsQuery,
  PaymentTargetType,
  useCreateAdditionalAgreementDocxMutation,
  useCreateTransferActForAdditionalAgreementDocxMutation,
  useDealForAdditionalAgreementByIdSuspenseQuery,
  usePaymentsSuspenseQuery
} from '../../../graphql/schema'
import SubmitButton from '../../../components/SubmitButton'
import SignatorySelectInput, { KimSignatory } from '../../../components/SignatorySelectInput.tsx'
import StylelessInput from '../../../components/Forms/Inputs/StylelessInput.tsx'
import StylelessMaskedNumberInput from '../../../components/Forms/Inputs/StylelessMaskedNumberInput.tsx'
import { CompanyData } from '../../../types/dadata.ts'
import {
  capitalizeFirst,
  capitalizeFirstInWords,
  getCompanyForm,
  getDateFormattedInDocumentName,
  getDateFormattedString,
  getFormattedContractNumber,
  isIP
} from '../../../utils/contractUtils.ts'
import { LEADER_STATUSES_GENITIVE_MAP } from '../models/constants.ts'
import getInclineFIO from '../../../utils/getInclineFIO.ts'
import useNodes from '../../../hooks/useNodes.ts'
import { NodeType } from '../../../types/index.ts'
import { daysBetween } from '../../../utils/dates.ts'
import { getCalendarFormatDate } from '../../../utils/dateFormatter.ts'
import downloadFile from '../../../utils/downloadFileServerless.ts'
import { formatDecimal } from '../../../utils/formatNumber.ts'
import usePersonAddress from '../../../hooks/usePersonAddress.ts'

type Payment = NodeType<PaymentsQuery['payments']>

interface AdditionalAgreementFormProps {
  onDone?: () => void
  dealId: number
}

const calculatePaymentAmountSinceLastPaid = (payments: Payment[], buyoutDate: string): number | null => {
  const lastPaidPaymentIndex = payments.findLastIndex((payment) => payment.state === 'PAID')

  if (lastPaidPaymentIndex === -1) {
    console.error("Нет платежей со статусом 'PAID'")
    return null
  }

  const lastPaidPayment = payments[lastPaidPaymentIndex]
  const nextPayment = payments[lastPaidPaymentIndex + 1]

  if (!nextPayment) {
    console.error("Нет следующего платежа после последнего 'PAID'")
    return null
  }

  const daysBetweenPayments = daysBetween(new Date(lastPaidPayment.date), new Date(nextPayment.date))
  const dailyPayment = lastPaidPayment.amount / daysBetweenPayments

  const dayAfterLastPaid = new Date(lastPaidPayment.date)
  dayAfterLastPaid.setDate(dayAfterLastPaid.getDate() + 1)

  const daysSinceLastPaid = daysBetween(dayAfterLastPaid, new Date(buyoutDate || '')) + 1

  const totalPayment = dailyPayment * daysSinceLastPaid

  return totalPayment
}

const findLastPaymentDate = (payments: Payment[]): number | null => {
  const lastPaidPaymentIndex = payments.findLastIndex((payment) => payment.state === 'PAID')

  if (lastPaidPaymentIndex === -1) {
    console.error("Нет платежей со статусом 'PAID'")
    return null
  }

  const lastPaidPaymentDate = new Date(payments[lastPaidPaymentIndex].date)

  return lastPaidPaymentDate.setDate(lastPaidPaymentDate.getDate() + 1)
}

const getRedemptionPaymentAfterLastPaid = (payments: Payment[]): number | undefined => {
  const lastPaidIndex = payments.findLastIndex((payment) => payment.state === 'PAID')
  if (lastPaidIndex !== -1 && payments[lastPaidIndex + 1]?.redemption) {
    return payments[lastPaidIndex + 1].redemption
  }

  return undefined
}

const AdditionalAgreementForm: FC<AdditionalAgreementFormProps> = ({ dealId }) => {
  const { data } = useDealForAdditionalAgreementByIdSuspenseQuery({ variables: { id: `${dealId}` } })

  const deal = data?.deal

  const customerCompany = deal?.customerCompany

  const { data: leasingPaymentsData } = usePaymentsSuspenseQuery({
    variables: {
      kind: PaymentKind.Leasing,
      targetType: PaymentTargetType.Deal,
      targetId: dealId.toString()
    },
    skip: !dealId
  })
  const payments = useNodes(leasingPaymentsData?.payments.edges)

  const dealSupplies = useNodes(deal?.dealSupplies?.edges)

  const items = dealSupplies.flatMap((dealSupply) => dealSupply.nomenclature)

  const [createAdditionalAgreement] = useCreateAdditionalAgreementDocxMutation()
  const [createTransferActForAddtionalAgreement] = useCreateTransferActForAdditionalAgreementDocxMutation()

  const companyDadata: CompanyData = useMemo(
    () => (deal?.customerCompany?.dadata ? deal?.customerCompany.dadata.data : {}),
    [deal?.customerCompany]
  )

  const IP: boolean = isIP(companyDadata)
  const companyName = IP ? capitalizeFirstInWords(companyDadata.name?.full) : capitalizeFirst(companyDadata.name?.full)

  const customerRequisites = useNodes(customerCompany?.bankDetails?.edges)

  let leaderStatus =
    LEADER_STATUSES_GENITIVE_MAP.get(companyDadata?.management?.post?.toLowerCase()) ||
    companyDadata?.management?.post?.toLowerCase() ||
    ''
  leaderStatus = capitalizeFirst(leaderStatus)
  const leaderStatusGenitiveWithName = companyDadata?.management?.name
    ? (leaderStatus + ' ' + getInclineFIO(companyDadata?.management.name) + ', действующего на основании Устава').trim()
    : ''

  const IPfactAddress = usePersonAddress(deal?.customerCompany.inn || '')

  const paymentsAmountSinceLastPaidForToday = calculatePaymentAmountSinceLastPaid(
    payments,
    getCalendarFormatDate(new Date())
  )

  const redemptionPayment = getRedemptionPaymentAfterLastPaid(payments) || 0

  const form = useForm<AdditionalAgreementFormInputs>({
    defaultValues: {
      leasingPaymentsAmount: formatDecimal((paymentsAmountSinceLastPaidForToday || 0) * 100),
      buyoutAmount: formatDecimal(redemptionPayment * 100 - (paymentsAmountSinceLastPaidForToday || 0) * 100),
      buyoutDate: getCalendarFormatDate(new Date()),
      ipFactAddress: IP ? companyDadata.address?.value : undefined,
      companyResponsibleFio: !IP ? companyDadata?.management?.name : undefined,
      companyResponsibleStatus: !IP ? capitalizeFirst(companyDadata?.management?.post) : undefined,
      companyResponsibleStatusGenitiveWithName: !IP
        ? leaderStatusGenitiveWithName.trim()
        : customerCompany?.shortWithOpf,
      signatory: KimSignatory
    }
  })
  const { register, handleSubmit, control, formState, watch, setValue } = form

  const buyoutDate = watch('buyoutDate')

  useEffect(() => {
    const paymentsAmountSinceLastPaid = calculatePaymentAmountSinceLastPaid(payments, buyoutDate) || 0
    const redemptionPayment = getRedemptionPaymentAfterLastPaid(payments) || 0

    const leasingPaymentsValue = Math.round(paymentsAmountSinceLastPaid * 100)
    const buyoutAmountValue = Math.round(redemptionPayment * 100 - leasingPaymentsValue)

    if (buyoutAmountValue < 0) {
      console.log(
        'Buyout Amount is negative due to Leasing Payments Amount exceeding Redemption Payment. Setting to 0.'
      )
    }

    setValue('leasingPaymentsAmount', formatDecimal(leasingPaymentsValue))
    setValue('buyoutAmount', formatDecimal(buyoutAmountValue >= 0 ? buyoutAmountValue : 0))
  }, [buyoutDate, payments, setValue])

  const onSubmit: SubmitHandler<AdditionalAgreementFormInputs> = async (data: AdditionalAgreementFormInputs) => {
    try {
      const createAdditionalAgreementResult = await createAdditionalAgreement({
        variables: {
          input: {
            leasingPaymentsAmount: data.leasingPaymentsAmount,
            buyoutAmount: data.buyoutAmount,
            buyoutDate: getDateFormattedString(new Date(data.buyoutDate)),
            feraFio: data.signatory.fio,
            feraStatus: data.signatory.status,
            feraGenitiveTitle: data.signatory.statusGenitiveWithName,
            companyResponsibleGenitive: !IP ? ` в лице ${data.companyResponsibleStatusGenitiveWithName}` : '',
            companyName: `${deal?.customerCompany?.fullWithOpf ? getCompanyForm(deal.customerCompany.fullWithOpf, companyName, true) : ''} ${IP ? companyName : '«' + companyName + '»'} (ИНН ${deal?.customerCompany?.inn})`,
            leasingReceiverName: `${deal?.customerCompany?.fullWithOpf ? getCompanyForm(deal.customerCompany.fullWithOpf, companyName, true) : ''} ${IP ? companyName : '«' + companyName + '»'}`,
            contractN: getFormattedContractNumber(deal?.contractNumber),
            contractDate: deal?.contractDate ? getDateFormattedString(new Date(deal.contractDate)) : '',
            companyAddress: `Адрес: ${IP ? IPfactAddress : companyDadata?.address?.unrestricted_value}`,
            companyResponsibleFio: !IP ? data.companyResponsibleFio : deal?.customerCompany.name,
            companyResponsibleStatus: !IP ? data.companyResponsibleStatus : 'Индивидуальный предприниматель',
            ogrn: IP ? `ОГРНИП ${companyDadata.ogrn}` : `ОГРН ${companyDadata.ogrn}`,
            inn: `ИНН ${companyDadata.inn}`,
            kpp: IP ? '' : `КПП ${companyDadata.kpp}`,
            lastPaymentDate: getDateFormattedString(new Date(findLastPaymentDate(payments) || '')),
            bik: `БИК ${customerRequisites[0].bic}`,
            correspondentAccount: `к/с ${customerRequisites[0].correspondentAccount}`,
            account: `р/с ${customerRequisites[0].account}`,
            bankName: customerRequisites[0].name
          }
        }
      })

      const additionalAgreementUrl = createAdditionalAgreementResult?.data?.createAdditionalAgreement?.url
      if (!additionalAgreementUrl) throw new Error('Не удалось получить ссылку на файл')

      await downloadFile(
        additionalAgreementUrl,
        `${getDateFormattedInDocumentName(new Date())} Дополнительное соглашение к ${getFormattedContractNumber(deal?.contractNumber)} от ${deal?.contractDate ? getDateFormattedString(new Date(deal.contractDate)) : ''} при досрочном исполнении обязательств от ${data?.buyoutDate ? getDateFormattedString(new Date(data.buyoutDate)) : ''}.docx`
      )

      const createTranferActForAdditionalAgreementResult = await createTransferActForAddtionalAgreement({
        variables: {
          input: {
            buyoutDate: getDateFormattedString(new Date(data.buyoutDate)),
            feraFio: data.signatory.fio,
            feraStatus: data.signatory.status,
            feraGenitiveTitle: data.signatory.statusGenitiveWithName,
            companyResponsibleGenitive: !IP ? ` в лице ${data.companyResponsibleStatusGenitiveWithName}` : '',
            companyName: `${deal?.customerCompany?.fullWithOpf ? getCompanyForm(deal.customerCompany.fullWithOpf, companyName, true) : ''} ${IP ? companyName : '«' + companyName + '»'} (ИНН ${deal?.customerCompany?.inn})`,
            leasingReceiverName: `${deal?.customerCompany?.fullWithOpf ? getCompanyForm(deal.customerCompany.fullWithOpf, companyName, true) : ''} ${IP ? companyName : '«' + companyName + '»'}`,
            contractN: getFormattedContractNumber(deal?.contractNumber),
            contractDate: deal?.contractDate ? getDateFormattedString(new Date(deal.contractDate)) : '',
            companyResponsibleFio: !IP ? data.companyResponsibleFio : deal?.customerCompany.name,
            companyResponsibleStatus: !IP ? data.companyResponsibleStatus : 'Индивидуальный предприниматель',
            items: items.map((i) => ({
              name: i.name,
              amount: `${i.count}`,
              units: i.unit
            }))
          }
        }
      })

      const tranferActForAdditionalAgreementUrl =
        createTranferActForAdditionalAgreementResult.data?.createTransferActForAdditionalAgreement?.url
      if (!tranferActForAdditionalAgreementUrl) throw new Error('Не удалось получить ссылку на файл')

      await downloadFile(
        tranferActForAdditionalAgreementUrl,
        `${getDateFormattedInDocumentName(new Date())} Акт приема передачи предмета лизинга к ${getFormattedContractNumber(deal?.contractNumber)} от ${deal?.contractDate ? getDateFormattedString(new Date(deal.contractDate)) : ''}.docx`
      )
    } catch (e) {
      console.error(e)
    }
  }

  if (!deal) return null

  return (
    <section className='w-[468px] py-10 lg:w-[748px]'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='px-12'>
          <h1 className='mb-12 text-xl font-semibold'>Досрочный выкуп</h1>

          <div className='rounded-xl bg-surface-secondary px-5 pb-5 pt-4 shadow-card'>
            <div className='mb-4 px-3 py-2 font-semibold'>Информация о выкупной стоимости</div>

            <div className='mb-10 rounded-md bg-base-white shadow-card'>
              <div className='grid grid-cols-5 divide-x-1 divide-separators-primary border-b-1 border-separators-primary'>
                <div className='col-span-2 px-7 py-4 text-labels-secondary'>Дата договора о выкупе</div>
                <div className='col-span-3'>
                  <StylelessInput
                    placeholder='дд.мм.гггг'
                    type='date'
                    error={formState.errors.buyoutDate}
                    {...register('buyoutDate')}
                  />
                </div>
              </div>

              <div className='grid grid-cols-5 divide-x-1 divide-separators-primary border-b-1 border-separators-primary'>
                <div className='col-span-2 px-7 py-4 text-labels-secondary'>Лизинговые платежи за период</div>
                <div className='col-span-3'>
                  <Controller
                    control={control}
                    name='leasingPaymentsAmount'
                    rules={{ required: true, min: 0 }}
                    render={({ field, fieldState }) => (
                      <StylelessMaskedNumberInput
                        placeholder='100 000,00'
                        inputMode='decimal'
                        {...field}
                        error={fieldState?.error}
                      />
                    )}
                  />
                </div>
              </div>

              <div className='grid grid-cols-5 divide-x-1 divide-separators-primary'>
                <div className='col-span-2 px-7 py-4 text-labels-secondary'>Стоимость досрочного выкупа</div>
                <div className='col-span-3'>
                  <Controller
                    control={control}
                    name='buyoutAmount'
                    rules={{ required: true, min: 0 }}
                    render={({ field, fieldState }) => (
                      <StylelessMaskedNumberInput
                        placeholder='4 500 000,00'
                        inputMode='decimal'
                        {...field}
                        error={fieldState?.error}
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className='mb-4 mt-8 px-3 py-2 font-semibold'>Ответственный за компанию клиента</div>

            <div className='rounded-md bg-base-white shadow-card'>
              {!IP && (
                <>
                  <div className='grid grid-cols-5 divide-x-1 divide-separators-primary border-b-1 border-separators-primary'>
                    <div className='col-span-2 px-7 py-4 text-labels-secondary'>Должность</div>
                    <div className='col-span-3'>
                      <StylelessInput
                        placeholder='Генеральный директор'
                        type='text'
                        {...register('companyResponsibleStatus')}
                        error={formState.errors.companyResponsibleStatus}
                      />
                    </div>
                  </div>

                  <div className='grid grid-cols-5 divide-x-1 divide-separators-primary border-b-1 border-separators-primary'>
                    <div className='col-span-2 px-7 py-4 text-labels-secondary'>ФИО ответственного</div>
                    <div className='col-span-3'>
                      <StylelessInput
                        placeholder='Иванов Иван Иванович'
                        {...register('companyResponsibleFio')}
                        error={formState.errors.companyResponsibleFio}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className='grid grid-cols-5 divide-x-1 divide-separators-primary'>
                <div className='col-span-2 px-7 py-4 text-labels-secondary'>Кто отвечает за компанию</div>
                <div className='col-span-3'>
                  <StylelessInput
                    placeholder='Генерального директора Иванова И. И.'
                    {...register('companyResponsibleStatusGenitiveWithName')}
                    error={formState.errors.companyResponsibleStatusGenitiveWithName}
                  />
                </div>
              </div>

              {IP && (
                <div className='grid grid-cols-5 divide-x-1 divide-separators-primary border-t-1 border-separators-primary'>
                  <div className='col-span-2 px-7 py-4 text-labels-secondary'>Юридический адрес</div>
                  <div className='col-span-3'>
                    <StylelessInput
                      placeholder='Иванов Иван Иванович'
                      {...register('ipFactAddress')}
                      error={formState.errors.ipFactAddress}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='mt-12 border-t-1 border-separators-primary px-12'>
          <div className='mt-10 grid grid-cols-2 gap-5'>
            <Controller
              name='signatory'
              control={control}
              render={({ field }) => (
                <SignatorySelectInput
                  label='Подписант Fera'
                  labelClassName='font-medium text-sm'
                  selected={field.value}
                  onChange={(selected) => field.onChange(selected)}
                  error={formState.errors.signatory?.message}
                />
              )}
            />
            <div className='flex h-full items-end'>
              <SubmitButton loading={formState.isSubmitting}>
                <FileIcon className='mr-5' />
                Сгенерировать
              </SubmitButton>
            </div>
          </div>
        </div>
      </form>
    </section>
  )
}

export default AdditionalAgreementForm
