import { forwardRef, ComponentPropsWithoutRef } from 'react'
import { FieldError } from 'react-hook-form'
import { IMaskInput, ReactMaskOpts } from 'react-imask'
import c from 'clsx'
import { errorToString } from '../../../utils/errorToString'

interface MaskedNumberInputProps extends Omit<ComponentPropsWithoutRef<'input'>, 'onChange'> {
  label?: string
  error?: string | FieldError
  value?: string
  onChange: (e: { target: { value: string } }) => void
}

const numberMaskOptions: ReactMaskOpts = {
  mask: Number,
  thousandsSeparator: ' ',
  scale: 2
}

/**
 * Компонент для ввода чисел с разделителем разрядов пробелами и разделителем дробной части запятой, без стилей
 */
const StylelessMaskedNumberInput = forwardRef<HTMLInputElement, MaskedNumberInputProps>(function Input(props, ref) {
  const { label, children, error, onChange, value, placeholder, inputMode } = props
  return (
    <div>
      {!!label && <div className='inp-label text-p350 mb-5'>{label}</div>}

      <label className='group relative block'>
        <IMaskInput
          value={value}
          {...numberMaskOptions}
          inputRef={ref}
          placeholder={placeholder}
          inputMode={inputMode}
          className={c(
            'w-full border-none bg-transparent px-7 py-4 tabular-nums placeholder-grayscale-250 outline-none transition-opacity',
            error && 'placeholder-base-red/70'
          )}
          onAccept={(v) => {
            onChange && onChange({ target: { value: v } })
          }}
        />
        {children}
        {error && <div className='text-p450 pl-4 text-base-red'>{errorToString(error)}</div>}
      </label>
    </div>
  )
})

export default StylelessMaskedNumberInput
