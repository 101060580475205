import useAccessCheck from '../../../hooks/useAccessCheck.ts'
import { DealStatus } from '../../../graphql/schema.tsx'
import { useMemo } from 'react'

const usePaymentsAccessCheck = (dealStatus?: DealStatus) => {
  const hasAccessPaymentsManageAfterDealSigning = useAccessCheck('deal.payments.manage_after_signing')
  const hasAccessServicePaymentsCreateAfterDealActivation = useAccessCheck(
    'deal.service_payments.create_after_deal_activation'
  )
  const hasAccessPenaltyPaymentsCreate = useAccessCheck('deal.penalty_payments.create')
  const hasAccessPenaltyPaymentsDelete = useAccessCheck('deal.penalty_payments.delete')
  const hasAccessPaymentsEditTransactionsDate = useAccessCheck('deal.payments.edit_transactions_date')
  const hasAccessPayPayment = useAccessCheck('deal.payments.pay_payment')
  const hasAccessDebtTransferServiceCreateAfterDealActivation = useAccessCheck(
    'deal.debt_transfer_payment.create_after_deal_activation'
  )
  const hasAccessPenaltyForTerminationCreateAfterDealActivation = useAccessCheck(
    'deal.penalty_for_termination.create_after_deal_activation'
  )
  const hasAccessFeeCreateAfterDealActivation = useAccessCheck('deal.fee.create_after_deal_activation')
  const hasAccessCompensationCreateAfterDealActivation = useAccessCheck(
    'deal.compensation.create_after_deal_activation'
  )
  const hasAccessBuyoutCreateAfterDealActivation = useAccessCheck('deal.buyout.create_after_deal_activation')
  const hasAccessBodyCreateAfterDealActivation = useAccessCheck('deal.body.create_after_deal_activation')
  const hasAccessInsuranceCreateAfterDealActivation = useAccessCheck('deal.insurance.create_after_deal_activation')

  return useMemo(() => {
    const isAfterDealActivationStatus =
      dealStatus && [DealStatus.PaymentReception, DealStatus.Closed].includes(dealStatus)
    const isAfterDealSigningStatus =
      dealStatus &&
      [DealStatus.Signing, DealStatus.Activation, DealStatus.PaymentReception, DealStatus.Closed].includes(dealStatus)

    return {
      canPaymentsManage: isAfterDealSigningStatus ? hasAccessPaymentsManageAfterDealSigning : true,
      canServicePaymentsCreate: !isAfterDealActivationStatus || hasAccessServicePaymentsCreateAfterDealActivation,
      canDebtTransferServicePaymentsCreate:
        !isAfterDealActivationStatus || hasAccessDebtTransferServiceCreateAfterDealActivation,
      canPenaltyForTerminationPaymentsCreate:
        !isAfterDealActivationStatus || hasAccessPenaltyForTerminationCreateAfterDealActivation,
      canFeePaymentsCreate: !isAfterDealActivationStatus || hasAccessFeeCreateAfterDealActivation,
      canCompensationPaymentsCreate: !isAfterDealActivationStatus || hasAccessCompensationCreateAfterDealActivation,
      canBuyoutPaymentsCreate: !isAfterDealActivationStatus || hasAccessBuyoutCreateAfterDealActivation,
      canBodyPaymentsCreate: !isAfterDealActivationStatus || hasAccessBodyCreateAfterDealActivation,
      canInsurancePaymentsCreate: !isAfterDealActivationStatus || hasAccessInsuranceCreateAfterDealActivation,
      canPenaltyPaymentsCreate: hasAccessPenaltyPaymentsCreate,
      canPenaltyPaymentsDelete: hasAccessPenaltyPaymentsDelete,
      canPaymentsEditTransactionsDate: hasAccessPaymentsEditTransactionsDate,
      canPayPayment: hasAccessPayPayment
    }
  }, [
    dealStatus,
    hasAccessBodyCreateAfterDealActivation,
    hasAccessBuyoutCreateAfterDealActivation,
    hasAccessCompensationCreateAfterDealActivation,
    hasAccessDebtTransferServiceCreateAfterDealActivation,
    hasAccessFeeCreateAfterDealActivation,
    hasAccessPayPayment,
    hasAccessPaymentsEditTransactionsDate,
    hasAccessPaymentsManageAfterDealSigning,
    hasAccessPenaltyForTerminationCreateAfterDealActivation,
    hasAccessPenaltyPaymentsCreate,
    hasAccessPenaltyPaymentsDelete,
    hasAccessServicePaymentsCreateAfterDealActivation,
    hasAccessInsuranceCreateAfterDealActivation
  ])
}

export default usePaymentsAccessCheck
