import { FC } from 'react'
import { ReactComponent as EditIcon } from '../../../svg/icons/edit.svg'
import { DocumentTypeEntity, GuarantorSignMethod, GuarantorStatus, useDocumentsQuery } from '../../../graphql/schema'
import { guarantorSignMethod, guarantorStatusDict } from '../../../utils/dictionaries'
import { dateFormatter } from '../../../utils/dateFormatter'
import { Link } from 'react-router-dom'
import { GUARANTOR_CONTRACT_FOLDER_NAME } from '../../../config'

interface GuarantorCompanyProps {
  id?: number
  companyId?: number
  onEdit: () => void
  contactId?: number
  name?: string
  inn?: string
  registrationDate?: string
  dateCreated?: string
  dateSigned?: string
  signMethod?: GuarantorSignMethod
  status: GuarantorStatus
}

const GuarantorCompany: FC<GuarantorCompanyProps> = ({
  id,
  companyId,
  onEdit,
  name,
  inn,
  registrationDate,
  dateCreated,
  dateSigned,
  signMethod,
  status
}) => {
  const { data } = useDocumentsQuery({
    variables: {
      filter: {
        entityId: `${id}`,
        entityType: DocumentTypeEntity.Guarantor,
        type: GUARANTOR_CONTRACT_FOLDER_NAME
      }
    },
    skip: !id
  })

  const hasDocs = !!data?.documents?.edges?.length

  return (
    <div className='px-4 pb-4'>
      <div>
        <h2 className='flex items-center justify-between px-6 pt-10 font-display text-h200'>
          <Link className='hover:text-red-100' to={`/guarantors/${companyId}`}>
            <h2 className='line-clamp-1 font-display text-h200'>{name}</h2>
          </Link>
          {hasDocs && <EditIcon onClick={onEdit} className='flex-none cursor-pointer text-grayscale-150' />}
        </h2>
        <div className='mb-10 px-6 text-grayscale-150'>Юридическое лицо</div>
      </div>
      <table className='conte w-full'>
        <tbody>
          <tr className='group'>
            <td className='flex px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
              ИНН
            </td>
            <td className='px-8 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
              <div className='flex items-center justify-end'>
                <div className='text-p200'>{inn}</div>
              </div>
            </td>
          </tr>
          <tr className='group'>
            <td className='flex px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
              Дата регистрации
            </td>
            <td className='px-8 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
              <div className='flex items-center justify-end'>
                <div className='text-p200'>{registrationDate && dateFormatter.format(new Date(registrationDate))}</div>
              </div>
            </td>
          </tr>
          <tr className='group'>
            <td className='flex px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
              Создано
            </td>
            <td className='px-8 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
              <div className='flex items-center justify-end'>
                <div className='text-p200'>{!!dateCreated && dateFormatter.format(new Date(dateCreated))}</div>
              </div>
            </td>
          </tr>
          <tr className='group'>
            <td className='flex px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
              Метод подписания
            </td>
            <td className='px-8 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
              <div className='flex items-center justify-end'>
                <div className='text-p200'>{!!signMethod && guarantorSignMethod[signMethod]}</div>
              </div>
            </td>
          </tr>
          <tr className='group'>
            <td className='flex px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
              Статус договора
            </td>
            <td className='px-8 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
              <div className='flex items-center justify-end'>
                <div className='text-p200'>{!!status && guarantorStatusDict[status]}</div>
              </div>
            </td>
          </tr>
          <tr className='group'>
            <td className='flex px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
              Дата подписания
            </td>
            <td className='px-8 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
              <div className='flex items-center justify-end'>
                <div className='text-p200'>
                  {dateSigned && status === GuarantorStatus.Signed ? dateFormatter.format(new Date(dateSigned)) : '—'}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default GuarantorCompany
