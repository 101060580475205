// deal supply folder names
export const COMMISSION_REWARD_FOLDER_ACT_NAME = 'Акт'
export const SHIPPING_FOLDER_NAME = 'Отгрузочные'
export const TRANSFER_FOLDER_NAME = 'Акт приёма-передачи'
export const COMMISSION_REWARD_FOLDER_INVOICE_NAME = 'Счёт-фактура'
export const SUPPLY_FOLDER_NAME = 'Договор поставки'
export const SPECIFICATIONS_FOLDER_NAME = 'Спецификация'

// deal supply shipmnet folder names
export const SHIPMENT_SPECS_FOLDER_NAME = 'Спецификация'

// guarantor folder names
export const GUARANTOR_CONTRACT_FOLDER_NAME = 'Договор поручительства'

// supplier folder names
export const KP_FOLDER_NAME = 'КП или Счёт'
