import { FC, useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Main from './Main'
import { ErrorBoundary } from '@sentry/react'
import Sidebar from '../components/Sidebar'
import c from 'clsx'
import ls from 'localstorage-slim'

import Applications from './Applications'
import Application from './Application'
import ApplicationInfo from './Application/Info'
import ApplicationSuppliers from './Application/Suppliers'
import ApplicationDocuments from './Application/Documents'
import ApplicationContacts from './Application/Contacts'
import ApplicationReconciliations from './Application/Reconciliations'
import ApplicationScoring from './Application/Scoring'

import Contacts from './Contacts'
import Contact from './Contact'
import ContactKYC from './Contact/KYC'
import ContactDocuments from './Contact/Documents'
import ContactApplications from './Contact/Applications'
import ContactBKI from './Contact/BKI'

import Customers from './Customers'
import Customer from '../containers/Customer'

import Suppliers from '../containers/Suppliers'
import Supplier from '../containers/Supplier'
import SupplierInfo from '../containers/Supplier/Info'
import SupplierApplications from '../containers/Supplier/Applications'
import SupplierDeals from '../containers/Supplier/Deals'
import SupplierDocuments from './Supplier/Documents'
import SupplierRetrobonuses from './Supplier/Retrobonuses'

import Deals from '../containers/Deals'
import Deal from './Deal'
import DealCollection from './Deal/Collection'
import DealInfo from './Deal/Info'
import DealDocuments from './Deal/Documents'
import Regulators from './Deal/Regulators'
import DealFinances from './Deal/Finances'
import Guarantors from './Deal/Guarantors'
import DealSupplies from './Deal/Supplies'
import Commission from './Deal/Commission'
import Notifications from './Deal/Notifications'

import Sources from '../containers/Sources'
import Source from './Source'
import SourceInfo from './Source/Info'
import SourceApplications from './Source/Applications'
import SourceDeals from './Source/Deals'
import SourceBonuses from './Source/Bonuses'
import SourceDocuments from './Source/Documents'

import Company from './Company'
import CompanyInfo from './Company/Info'
import CompanyScoring from './Company/Scoring'
import CompanyDocuments from './Company/Documents'

import TresCommas from './Tres'
import ErrorFallback from '../components/ErrorFallback'

import BankOperations from './BankOperations'

import FundingSources from './FundingSources'
import FundingSource from './FundingSource'
import FundingSourceInfo from './FundingSource/Info'
import FundingSourceDocuments from './FundingSource/Documents'
import Toaster from '../components/Toaster'

import useTrackInactivity from '../hooks/useTrackInactivity'
import Modal from '../components/Modal'
import ConfirmationForm from '../components/ConfirmationForm'

const Layout: FC = () => {
  const [rollup, setRollup] = useState<boolean>(JSON.parse(ls.get('rollup') || 'false'))

  const [inactive, setInactive] = useTrackInactivity()
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    if (inactive && !isModalOpen) {
      setIsModalOpen(true)
    }
  }, [inactive, isModalOpen])

  return (
    <div className='flex min-h-screen'>
      <Sidebar
        rollup={rollup}
        onRollup={() => {
          ls.set('rollup', JSON.stringify(!rollup))
          setRollup(!rollup)
        }}
      />

      <div className={c('flex w-full justify-center', rollup ? 'ml-30' : 'ml-145')}>
        <ErrorBoundary fallback={({ error }) => <ErrorFallback error={error as Error} />}>
          <Toaster />
          <Routes>
            <Route path='/' element={<Main />} />

            <Route path='applications' element={<Applications />} />
            <Route path='applications/:id' element={<Application />}>
              <Route index element={<ApplicationInfo />} />
              <Route path='suppliers' element={<ApplicationSuppliers />} />
              <Route path='scoring' element={<ApplicationScoring />} />
              <Route path='reconciliations' element={<ApplicationReconciliations />} />
              <Route path='docs' element={<ApplicationDocuments />} />
              <Route path='contacts' element={<ApplicationContacts />} />
            </Route>

            <Route path='contacts' element={<Contacts />} />
            <Route path='contacts/:id' element={<Contact />}>
              <Route index element={<ContactKYC />} />
              <Route path='docs' element={<ContactDocuments />} />
              <Route path='applications' element={<ContactApplications />} />
              <Route path='bki' element={<ContactBKI />} />
            </Route>

            <Route path='customers' element={<Customers />} />
            <Route path='customers/:id/*' element={<Customer />} />

            <Route path='suppliers' element={<Suppliers />} />
            <Route path='suppliers/:id' element={<Supplier />}>
              <Route index element={<SupplierInfo />} />
              <Route path='applications' element={<SupplierApplications />} />
              <Route path='retrobonuses' element={<SupplierRetrobonuses />} />
              <Route path='deals' element={<SupplierDeals />} />
              <Route path='docs' element={<SupplierDocuments />} />
            </Route>

            <Route path='deals' element={<Deals />} />
            <Route path='deals/:id' element={<Deal />}>
              <Route index element={<DealInfo />} />
              <Route path='collection' element={<DealCollection />} />
              <Route path='regulators' element={<Regulators />} />
              <Route path='finances' element={<DealFinances />} />
              <Route path='guarantors' element={<Guarantors />} />
              <Route path='supplies' element={<DealSupplies />} />
              <Route path='commission' element={<Commission />} />
              <Route path='notifications' element={<Notifications />} />
              <Route path='docs' element={<DealDocuments />} />
            </Route>

            <Route path='sources' element={<Sources />} />
            <Route path='sources/:id' element={<Source />}>
              <Route index element={<SourceInfo />} />
              <Route path='applications' element={<SourceApplications />} />
              <Route path='deals' element={<SourceDeals />} />
              <Route path='bonuses' element={<SourceBonuses />} />
              <Route path='docs' element={<SourceDocuments />} />
            </Route>

            <Route path='/bank_operations' element={<BankOperations />} />

            <Route path='funding_sources' element={<FundingSources />} />
            <Route path='funding_sources/:id' element={<FundingSource />}>
              <Route index element={<FundingSourceInfo />} />
              <Route path='docs' element={<FundingSourceDocuments />} />
            </Route>

            <Route path='guarantors/:id' element={<Company />}>
              <Route index element={<CompanyInfo />} />
              <Route path='scoring' element={<CompanyScoring />} />
              <Route path='docs' element={<CompanyDocuments />} />
            </Route>

            <Route path='tres' element={<TresCommas />} />
          </Routes>
        </ErrorBoundary>
        {/* Модальное окно, которое показывается при неактивности пользователя */}
        <Modal open={isModalOpen} setOpen={setIsModalOpen}>
          <div className='z-10 rounded-xl bg-white-0 p-8'>
            <ConfirmationForm
              title='Сессия устарела'
              onDone={() => {
                setInactive(false)
                setIsModalOpen(false)
                window.location.reload()
              }}
              onDismiss={() => {
                setInactive(false)
                setIsModalOpen(false)
              }}
              yesText='Обновить страницу'
              noText='Обновлю позже'
            >
              Вкладка долгое время была неактивна, данные могли устареть. Хотите обновить страницу?
            </ConfirmationForm>
          </div>
        </Modal>
      </div>
    </div>
  )
}

export default Layout
