import { FC, useState } from 'react'
import {
  Kind,
  PaymentKind,
  PaymentState,
  PaymentTargetType,
  useCreatePaymentScheduleMutation,
  useDealForSchedulesSuspenseQuery,
  usePaymentsSuspenseQuery,
  useScheduleByDealParamsLazyQuery
} from '../../../graphql/schema.tsx'
import c from 'clsx'
import { Card, CardHeader, CardIconButton, CardMenu, CardTitle } from '../../../components/Card.tsx'
import { ReactComponent as PlusWithLineIcon } from '../../../svg/ui/application.svg'
import { ReactComponent as PlusIcon } from '../../../svg/ui/plus.svg'
import { ReactComponent as TrashIcon } from '../../../svg/ui/delete.svg'
import { ReactComponent as LoadingIcon } from '../../../svg/ui/refresh.svg'
import { ReactComponent as CheckIcon } from '../../../svg/icons/slimCheck.svg'
import { ReactComponent as ChevronDownSmall } from '../../../svg/ui/chevron-down-small.svg'
import { formatDecimal } from '../../../utils/formatNumber.ts'
import { REGULAR_VAT_RATE } from '../../../utils/constants.ts'
import { IMaskInput, ReactMaskOpts } from 'react-imask'
import parseDecimal, { parseDecimalFromMasked } from '../../../utils/parseDecimal.ts'
import { suspend } from 'suspend-react'
import { showPopup } from '../../../components/Toaster/showPopup.tsx'
import { uuid } from '../../../utils/uuid.ts'
import useNodes from '../../../hooks/useNodes.ts'
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { addMonths } from 'date-fns'
import Checkbox from '../../../components/Forms/Checkbox.tsx'
import { percentProps } from '../../../components/Forms/Inputs/PercentNumberInput.tsx'
import { paymentKindDict } from '../../../utils/dictionaries.ts'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'

type ScheduleItem = {
  _id: string
  amount: string
  date: string
  kind?: PaymentKind
  locked?: boolean
}

const numberMaskOptions: ReactMaskOpts = {
  mask: Number,
  thousandsSeparator: ' ',
  scale: 2
}

interface PaymentFormProps {
  dealId: number
  onDone?: () => void
}

interface PaymentsFormValues {
  payments: ScheduleItem[]
  documentDate?: string
  revisionRate?: string
}

const ScheduleForm: FC<PaymentFormProps> = ({ dealId, onDone }) => {
  const [isRevisionRate, setIsRevisionRate] = useState(false)
  const [lastRevisionRateValue, setLastRevisionRateValue] = useState<string>()

  const { data: dealData } = useDealForSchedulesSuspenseQuery({
    variables: {
      id: `${dealId}`
    },
    fetchPolicy: 'network-only'
  })
  const deal = dealData?.deal

  const { data: existPaymentsData } = usePaymentsSuspenseQuery({
    variables: {
      kinds: [PaymentKind.Leasing, PaymentKind.Fee, PaymentKind.Body, PaymentKind.Buyout, PaymentKind.Redemption],
      targetType: PaymentTargetType.Deal,
      targetId: dealId.toString()
    },
    fetchPolicy: 'network-only'
  })
  const existPayments = useNodes(existPaymentsData?.payments.edges)

  const [scheduleByDealParams] = useScheduleByDealParamsLazyQuery()

  const paymentsData: ScheduleItem[] = suspend(async () => {
    if (!deal) return []
    if (existPayments.length)
      return existPayments.map((p) => ({
        _id: p.id,
        amount: formatDecimal(p.amount * 100),
        date: p.date,
        kind: p.kind,
        locked: p.state !== PaymentState.Pending || p.penaltyPayments?.length > 0
      }))

    const { data: scheduleData } = await scheduleByDealParams({
      variables: {
        dealParams: {
          amount: deal.amount / 100,
          advanceRate: deal.advanceRate,
          interestRate: deal.interestRate,
          durationMonths: deal.durationMonths,
          comissionRate: deal.comissionRate,
          insuranceRate: deal?.insuranceAmount ? Math.round((deal?.insuranceAmount / deal?.amount) * 10000) / 100 : 0,
          vatRate: deal?.kind === Kind.Medicine ? 0 : REGULAR_VAT_RATE,
          advancePaymentDate: deal?.advancePaymentDate
        }
      }
    })

    // надо убрать uuid когда будем получать id с бэка
    const payments: ScheduleItem[] =
      scheduleData?.scheduleByDealParams.map((s) => ({
        _id: uuid().toString(),
        amount: formatDecimal(s.amount * 100),
        date: s.date,
        kind: PaymentKind.Leasing
      })) || []

    const d = new Date(deal.advancePaymentDate || '')
    d.setDate(d.getDate() - 3)
    d.setMonth(d.getMonth() + deal.durationMonths)
    payments.push({
      _id: uuid().toString(),
      amount: '1000',
      date: d.toISOString().split('T').shift() || '',
      kind: PaymentKind.Redemption
    })

    return payments
  }, [deal, existPaymentsData])

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isSubmitting }
  } = useForm<PaymentsFormValues>({
    defaultValues: {
      payments: paymentsData
    }
  })
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'payments',
    rules: {
      validate: {
        // isValidDate: (value: ScheduleItem[]) => value.every((v) => !!v.date) || 'Дата платежа не может быть пустой',
        // isValidAmount: (value: ScheduleItem[]) => value.every((v) => !!v.amount) || 'Сумма платежа не может быть пустой'
        isNotEmpty: (value) => {
          return (value as ScheduleItem[])?.length > 0 || 'Нужен как минимум один платёж'
        },
        isFilled: (value) => {
          return (value as ScheduleItem[]).every((v) => !!v.date && !!v.amount) || 'Заполните все поля'
        }
      }
    }
  })

  const [createPaymentSchedule] = useCreatePaymentScheduleMutation()

  const onSubmit: SubmitHandler<PaymentsFormValues> = async (data) => {
    await createPaymentSchedule({
      variables: {
        input: {
          dealId: dealId.toString(),
          paymentsData: data.payments.map((p) => ({
            amount: parseDecimalFromMasked(p.amount),
            date: new Date(p.date),
            kind: p?.kind || undefined
          })),
          documentDate: data.documentDate ? new Date(data.documentDate).toISOString() : undefined
        }
      }
    }).then(({ data }) => {
      if (data?.createPaymentSchedule?.errors?.length)
        return showPopup({
          title: 'Ошибка',
          subtitle: data.createPaymentSchedule.errors.map((e) => e.message).toString()
        })

      if (onDone) onDone()
    })
  }

  const handlePaymentAdd = () => {
    const payments = getValues('payments')
    const lastPayment = payments[payments.length - 1]
    const leasingPayments = payments.filter((p) => p.kind === PaymentKind.Leasing)
    const lastLeasingPayment = leasingPayments[leasingPayments.length - 1]

    let newDate = ''
    if (lastPayment?.date) {
      const date = addMonths(new Date(lastPayment.date), 1)
      newDate = date.toISOString().split('T')[0]
    }

    let newAmount = ''
    if (lastLeasingPayment?.amount) {
      newAmount = lastLeasingPayment.amount
    }

    append({ _id: uuid().toString(), kind: PaymentKind.Leasing, amount: newAmount, date: newDate })
  }

  const revisionRateChanged = () => {
    const value = getValues('revisionRate')
    const revisionRate = value ? parseDecimal(value) / 100 : null
    if (!revisionRate || lastRevisionRateValue === value) return

    const monthAfter = new Date()
    monthAfter.setMonth(monthAfter.getMonth() + 1)
    const payments = getValues('payments')
    const duration = payments.filter((p) => p.kind === PaymentKind.Leasing).length
    const updatedPayments = payments.map((payment) => {
      if (payment.kind === PaymentKind.Leasing && !payment.locked && new Date(payment.date) > new Date(monthAfter)) {
        const newAmount =
          Math.round(parseDecimalFromMasked(payment.amount) * 100) * (1 + 0.5 * revisionRate * (duration / 12))

        return { ...payment, amount: formatDecimal(newAmount) }
      }
      return payment
    })
    setValue('payments', updatedPayments)
    setLastRevisionRateValue(value)
  }

  const leasingPayments = fields.filter((f) => f.kind === PaymentKind.Leasing)

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='w-[680px]'>
      <h1 className='px-12 py-10 font-display text-h200'>Создание нового графика платежей</h1>

      <div className='border-t-1 border-separators-primary px-12 py-10'>
        <div className='mb-10 grid grid-cols-2 gap-10'>
          <div>
            <Card className='px-5 py-4'>
              Дата документа об изменении
              <section className='mt-10 grid grid-cols-2 rounded-lg bg-surface-primary shadow-card'>
                <div className='px-7 py-4'>Дата</div>
                <Controller
                  name='documentDate'
                  control={control}
                  render={({ field }) => {
                    return (
                      <label className='relative border-b-1 border-l-1 border-separators-primary'>
                        <input
                          type='date'
                          placeholder='дд.мм.гггг'
                          className='absolute inset-0 border-0 bg-transparent px-7 py-4 outline-none focus:border-0 focus:ring-transparent disabled:text-labels-secondary data-[invalid=true]:ring-2 data-[invalid=true]:ring-inset data-[invalid=true]:ring-base-red/25'
                          value={field.value}
                          onChange={(e) => field.onChange(e.target.value)}
                        />
                      </label>
                    )
                  }}
                />
              </section>
            </Card>
          </div>

          <div>
            <Card className='px-5 py-4'>
              <Checkbox
                label='Изменение ключевой ставки ЦБ'
                checked={isRevisionRate}
                onChange={(checked) => setIsRevisionRate(checked)}
              />

              {isRevisionRate && (
                <section className='mt-8 grid grid-cols-2 rounded-lg bg-surface-primary shadow-card'>
                  <div className='px-7 py-4'>Увеличение, %</div>
                  <Controller
                    name='revisionRate'
                    control={control}
                    render={({ field }) => {
                      return (
                        <label className='group relative border-l-1 border-separators-primary font-mono'>
                          <IMaskInput
                            {...percentProps}
                            value={field.value}
                            className='absolute inset-0 border-0 bg-transparent px-7 py-4 outline-none focus:border-0 focus:ring-transparent disabled:text-labels-secondary'
                            onPaste={(e) => field.onChange(e.clipboardData.getData('text/plain').trim())}
                            onChange={(value) => field.onChange(value)}
                            onBlur={revisionRateChanged}
                            onKeyDown={(e) => {
                              if (e.code === 'Enter') {
                                e.preventDefault()
                                revisionRateChanged()
                              }
                            }}
                          />
                          {lastRevisionRateValue !== field.value && (
                            <button
                              type='button'
                              className='invisible absolute bottom-4 right-8 cursor-pointer text-labels-tertiary hover:text-base-red group-focus-within:visible'
                              onClick={revisionRateChanged}
                            >
                              <CheckIcon width={20} height={20} />
                            </button>
                          )}
                        </label>
                      )
                    }}
                  />
                </section>
              )}
            </Card>
          </div>
        </div>

        <Card>
          <CardHeader>
            <CardTitle>График платежей</CardTitle>
            <CardMenu>
              <CardIconButton
                type='button'
                onClick={() => {
                  // delete all payments except locked
                  const payments = getValues('payments')
                  replace(payments.filter((p) => p.locked))
                }}
              >
                <TrashIcon />
              </CardIconButton>
            </CardMenu>
          </CardHeader>
          <div className='px-5 pb-5'>
            <div className='grid w-full grid-cols-[1fr_auto_1fr_auto] rounded-lg bg-surface-primary shadow-card'>
              <div className='contents border-b-1 border-separators-primary text-labels-tertiary'>
                <div className='border-b-1 border-separators-primary px-7 py-4'>Назначение</div>
                <div className='min-w-80 border-b-1 border-l-1 border-separators-primary px-7 py-4'>Дата</div>
                <div className='col-span-2 border-b-1 border-l-1 border-separators-primary px-7 py-4'>Сумма, ₽</div>
              </div>
              {fields.map((field, index) => {
                const locked = !!getValues(`payments.${index}.locked`)
                return (
                  <section key={field.id} className='contents font-mono'>
                    <div className='border-b-1 border-separators-primary text-labels-tertiary'>
                      <Menu as='div' className='relative'>
                        <MenuButton
                          className='group flex w-full items-center justify-between px-7 py-4 text-left'
                          disabled={locked}
                        >
                          {field.kind === PaymentKind.Leasing
                            ? `Лизинговый №${leasingPayments.findIndex((p) => p._id === field._id) + 1}`
                            : field.kind && paymentKindDict[field.kind]}
                          {!locked && (
                            <ChevronDownSmall
                              width={14}
                              height={14}
                              className='transition-transform duration-200 group-data-open:-rotate-180 group-data-open:text-base-red'
                            />
                          )}
                        </MenuButton>
                        <MenuItems
                          as='div'
                          className='absolute right-0 z-20 mt-1 w-full origin-top-right overflow-hidden rounded-b-lg bg-white-0 shadow focus:outline-none'
                        >
                          {[
                            PaymentKind.Leasing,
                            PaymentKind.Fee,
                            PaymentKind.Body,
                            PaymentKind.Buyout,
                            PaymentKind.Redemption
                          ].map((option) => (
                            <MenuItem
                              as='button'
                              className='flex w-full items-center px-7 py-4 text-left hover:bg-surface-secondary'
                              key={option}
                              onClick={() => {
                                const payments = getValues('payments')
                                payments[index].kind = option
                                replace(payments)
                              }}
                            >
                              {option === PaymentKind.Leasing ? 'Лизинговый' : paymentKindDict[option]}
                            </MenuItem>
                          ))}
                        </MenuItems>
                      </Menu>
                    </div>

                    <div className='relative border-b-1 border-l-1 border-separators-primary'>
                      <input
                        className='absolute inset-0 border-0 bg-transparent px-7 py-4 outline-none focus:border-0 focus:ring-transparent disabled:text-labels-secondary data-[invalid=true]:ring-2 data-[invalid=true]:ring-inset data-[invalid=true]:ring-base-red/25'
                        type='date'
                        disabled={!!getValues(`payments.${index}.locked`)}
                        data-invalid={!!errors.payments?.[index]?.date}
                        {...register(`payments.${index}.date`, { required: 'Дата платежа не может быть пустой' })}
                        placeholder={'дд.мм.гггг'}
                      />
                    </div>

                    <div
                      className={c('relative border-b-1 border-l-1 border-separators-primary', locked && 'col-span-2')}
                    >
                      <Controller
                        control={control}
                        name={`payments.${index}.amount`}
                        rules={{ required: 'Сумма платежа не может быть пустой' }}
                        render={({ field: { onChange, value } }) => (
                          <IMaskInput
                            {...numberMaskOptions}
                            className='absolute inset-0 border-0 bg-transparent px-7 py-4 outline-none focus:border-0 focus:ring-transparent disabled:text-labels-secondary data-[invalid=true]:ring-2 data-[invalid=true]:ring-inset data-[invalid=true]:ring-base-red/25'
                            value={value}
                            data-invalid={!!errors.payments?.[index]?.amount}
                            onAccept={(value) => onChange(value)}
                            disabled={!!getValues(`payments.${index}.locked`)}
                          />
                        )}
                      />
                    </div>

                    {!locked && (
                      <button
                        type='button'
                        disabled={locked}
                        onClick={() => {
                          remove(index)
                        }}
                        className='flex items-center border-b-1 border-l-1 border-separators-primary px-7 text-labels-tertiary hover:text-base-red'
                      >
                        <TrashIcon className='mx-auto cursor-pointer' />
                      </button>
                    )}
                  </section>
                )
              })}

              <div className='col-span-4'>
                <button
                  type='button'
                  className='flex w-full items-center gap-4 px-7 py-4 text-body-s text-labels-tertiary hover:text-base-red'
                  onClick={handlePaymentAdd}
                >
                  <PlusIcon />
                  Добавить платёж
                </button>
              </div>
            </div>
          </div>
        </Card>
      </div>

      {errors.payments?.root && <div className='px-12 pb-4 text-base-red'>{errors.payments.root.message}</div>}

      <div className='px-12 pb-10'>
        <button
          className='flex items-center gap-3 rounded-md bg-base-red px-24 py-5 text-base-white disabled:bg-grayscale-450 disabled:text-grayscale-250'
          onClick={() => handleSubmit(onSubmit)}
        >
          {isSubmitting ? <LoadingIcon className='animate-spin' /> : <PlusWithLineIcon />}
          Создать
        </button>
      </div>
    </form>
  )
}

export default ScheduleForm
